import React, { FC } from 'react'
import CloseButtonWithTooltip from '@ui/components/CloseButtonWithTooltip'

import usePageProfile from '../hooks/usePageProfile'

import styles from './PageProfileDialogHeader.module.css'

type PageProfileDialogHeaderProps = {
  title: string
}
const PageProfileDialogHeader: FC<PageProfileDialogHeaderProps> = ({
  title,
}) => {
  const { pageProfileFormState } = usePageProfile()

  return (
    <div className={styles.Root}>
      <div className={styles.Spacer}></div>
      <h4 className="">{title}</h4>
      <CloseButtonWithTooltip onClose={pageProfileFormState.onCancel} gray />
    </div>
  )
}

export default PageProfileDialogHeader
