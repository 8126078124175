import { useEffect, useRef, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { AnimatedDialog, useDialog } from 'boards-web-ui'

import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'
import DoneButton from 'ui/components/DoneButton'
import InputModalTitle from '../../../../elements/InputModalTitle'
import urlHelper from '../../../../helpers/url'
import { getPageTitle } from '../../../../_firebase'
import { NodeType } from '../../../models'
import ModalEditWithConfirmation from '../../../../components/ModalEditWithConfirmation'
import useNodeAPI from '../hooks/useNodeAPI'
import useNodeDiscardChanges from '../hooks/useNodeDiscardChanges'

import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'
import EditLinkTextArea from './EditLinkTextArea'

import styles from './EditLink.module.css'

interface Props {
  initialTitle: string
  initialText: string
  edit?: boolean
  onSave: ICreateOrUpdateNode
}

const EditLink = ({ edit, initialTitle, initialText, onSave }: Props) => {
  const { close } = useDialog()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(edit ? 2 : 1)

  const discardChanges = useNodeDiscardChanges()
  const { title, text, hasChanges, setTitle, setText } = useNodeAPI(
    initialTitle,
    initialText,
  )
  const [errorMessage, setErrorMessage] = useState<null | string>(null)

  const ref = useRef(null)
  const inputRef = useRef<HTMLTextAreaElement>()

  useEffect(() => {
    if (step === 1) {
      inputRef?.current?.focus()
    }
  }, [step])

  const getTitle = async () => {
    if (urlHelper.isValidUrl(urlHelper.withHttp(text))) {
      setErrorMessage(null)
      setLoading(true)
      const titleContent = await getPageTitle(urlHelper.withHttp(text))
      setStep(2)
      setTitle(titleContent)
      setLoading(false)
      return
    }

    setErrorMessage(t('node_link_invalid_url'))
  }

  const handleSave = () => {
    if (urlHelper.isValidUrl(urlHelper.withHttp(text))) {
      onSave({
        title,
        text: urlHelper.withHttp(text),
        type: NodeType.LINK,
      })
      close()
      return
    }

    setErrorMessage(t('node_link_invalid_url'))
  }

  const handleClose = () => {
    if (hasChanges) {
      discardChanges()
      return
    }

    close()
  }

  const disabled = text === undefined || text.length === 0
  const linkIcon = `https://s2.googleusercontent.com/s2/favicons?sz=32&domain_url=${text}`

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: close,
        onOverlayClick: step === 1 ? getTitle : handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            step === 1 ? (
              <DoneButton
                onClick={getTitle}
                disabled={disabled || loading}
                loading={loading}
              >
                <Trans i18nKey="action_next" />
              </DoneButton>
            ) : (
              <DoneButton onClick={handleSave}>
                <Trans i18nKey="action_done" />
              </DoneButton>
            )
          }
        >
          <>
            {step === 2 && (
              <InputModalTitle
                value={title}
                placeholder={t('add_an_update_title_optional')}
                onChange={setTitle}
              />
            )}

            <div className={styles.Field} ref={ref}>
              <div className={styles.InputLink}>
                {step === 2 && (
                  <img
                    className={styles.Favicon}
                    src={linkIcon}
                    alt="Favicon"
                    loading="lazy"
                  />
                )}

                <EditLinkTextArea
                  inputRef={inputRef}
                  error={errorMessage}
                  onChange={setText}
                  value={text}
                  step={step}
                />
              </div>
            </div>
          </>
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default EditLink
