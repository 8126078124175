import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  CopyIcon,
  FolderIcon,
  Menu,
  MenuItem,
  PremiumIndicationIcon,
  TrashIcon,
} from 'boards-web-ui'
import { useDestinationPicker } from 'app/features/pickers/destinationPicker'
import useNode from '@features/board/hooks/useNode'
import useBoard from '@hooks/useBoard'
import useBoardMode from '@features/board/hooks/useBoardMode'
import { ContentPickersEnum } from '@features/pickers'
import { AnalyticsNodeType } from '@features/analytics/useNodeEvents'
import { useCopyReceivedContent } from '@hooks/useCopyReceivedContent'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import usePaywall from '@hooks/usePaywall'
import { IconWrapper } from '@ui/components/IconWrapper'

import styles from './NodeActionsMenu.module.css'
import useNodeDeletorAPI from '../../nodeEditor/hooks/useNodeDeletorAPI'
import { Node, NodeType } from '../../../models'
import useConfirmDialog from '../../../../hooks/useConfirmDialog'

type MenuProps = {
  node: Node
  onItemClick: () => void
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  isReadOnly: boolean
}

export const NodeActionsMenu: React.FC<MenuProps> = ({
  node,
  onItemClick,
  setMenuOpen,
  isReadOnly,
}) => {
  const { boardId, folder, isPageBoard, isActiveBoard } = useBoard()
  const mode = useBoardMode()

  const { t } = useTranslation()
  const { openDestinationPicker } = useDestinationPicker({
    onClose: (picker: ContentPickersEnum) => {
      if (picker === ContentPickersEnum.MovePicker) {
        setMenuOpen(false)
        onItemClick()
      }
    },
  })

  const { onSelect } = useNode({
    boardId,
    folderId: folder.id,
    mode,
    folder,
    isSharedFolderMode: false,
  })

  const confirmDialog = useConfirmDialog()
  const { deleteNode } = useNodeDeletorAPI(boardId, folder.id)

  const onClick = () => {
    setMenuOpen(false)

    confirmDialog({
      title: t('confirmation_message_delete_selected_key'),
      message: t('dialog_delete_this_key'),
      confirmText: t('action_delete'),
      cancelText: t('action_cancel'),
      onConfirm: () => {
        if (onItemClick) {
          onItemClick()
        }

        deleteNode(node)
      },
    })
  }

  const { canCopy, onCopyClick } = useCopyReceivedContent()
  const { isFreePlanUser } = useReverseTrialContext()

  const { openPaywall } = usePaywall()

  const isLimitedPageNodeInActivePage =
    isFreePlanUser &&
    node.type === NodeType.PAGE &&
    (isPageBoard || !isActiveBoard)

  const isLimitedPageNodeInNotActivePage =
    isFreePlanUser && node.type === NodeType.PAGE && !isActiveBoard

  const handleCopyClick = useCallback(() => {
    if (isLimitedPageNodeInActivePage) {
      openPaywall(PaywallSourceEnum.COPY_CONTENT)
      return
    }

    setMenuOpen(false)

    onCopyClick({
      source: PaywallSourceEnum.COPY_CONTENT,
      pickerProps: {
        headerCopy: t('destination_picker_copy_to'),
        performActionCopy: t('destination_picker_paste_here'),
        performActionSuccessMessage: t('toast_content_copied'),
        contentType: AnalyticsNodeType[node.type],
      },
      onBeforeOpenPicker: () => onSelect(node.id, node.type),
      isPreviewMode: true,
    })
  }, [
    isLimitedPageNodeInActivePage,
    node.id,
    node.type,
    onCopyClick,
    onSelect,
    openPaywall,
    setMenuOpen,
    t,
  ])

  const startCopyIcon = () => {
    if (canCopy) {
      return <CopyIcon />
    }
    return isLimitedPageNodeInActivePage ? null : <PremiumIndicationIcon />
  }

  return (
    <Menu as="div">
      <MenuItem as="button" name="copy" onClick={handleCopyClick}>
        <IconWrapper size={20} className={styles.ItemIcon}>
          {startCopyIcon()}
        </IconWrapper>

        {t('action_copy')}

        {isLimitedPageNodeInActivePage ? (
          <>
            <span style={{ marginLeft: '6px' }} />

            <PremiumIndicationIcon fontSize={'20px'} />
          </>
        ) : null}
      </MenuItem>

      {isReadOnly ? null : (
        <>
          {isPageBoard || isLimitedPageNodeInNotActivePage ? null : (
            <MenuItem
              as="button"
              name="move"
              onClick={() => {
                onSelect(node.id, node.type)
                openDestinationPicker({
                  picker: ContentPickersEnum.MovePicker,
                  contentType: AnalyticsNodeType[node.type],
                  headerCopy: <Trans i18nKey={'destination_picker_move_to'} />,
                  performActionCopy: (
                    <Trans i18nKey={'destination_picker_move_here'} />
                  ),
                  performActionSuccessMessage: t('toast_content_moved'),
                })
              }}
            >
              <IconWrapper className={styles.ItemIcon}>
                <FolderIcon />
              </IconWrapper>
              {t('action_move')}
            </MenuItem>
          )}

          <MenuItem as="button" name="delete" onClick={onClick}>
            <IconWrapper className={styles.ItemIcon}>
              <TrashIcon />
            </IconWrapper>

            {t(
              isPageBoard || node.type === NodeType.PAGE
                ? 'action_delete_page'
                : 'action_delete',
            )}
          </MenuItem>
        </>
      )}
    </Menu>
  )
}
