import { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  Grid,
  SpinnerIcon,
  RetryIcon,
  AnimatedDialog,
  useDialog,
} from 'boards-web-ui'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import { VideoMetaData } from '@hooks/useUploadFileMetadata'
import { useSelectorFactory } from '@hooks/useSelectorFactory'

import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'
import DoneButton from 'ui/components/DoneButton'

import useUploadFirebase from '@hooks/useUploadFirebase'
import useRouterParams from '@hooks/useRouterParams'
import { getCurrentFolderId } from 'selectors'
import { Node, NodeType } from '../../../models'
import { videoActions } from '../../../../actions'
import { configurationObjectFromEnv } from '../../../../utils/configurationObjectFromEnv'
import { ENV_KEYS } from '../../../../utils/envKeys'
import ModalEditWithConfirmation from '../../../../components/ModalEditWithConfirmation'

import ProgressBar from './ProgressBar'
import VideoStreaming from './VideoStreaming'
import InputModalTitle from '../../../../elements/InputModalTitle'

import useNodeAPI from '../hooks/useNodeAPI'
import useNodeDiscardChanges from '../hooks/useNodeDiscardChanges'
import useAddVideoStreamingDialogs from '../hooks/useAddVideoStreamingDialogs'

import { ICreateOrUpdateNode, INodeProps } from '../models/IUseNodeCreatorAPI'

import styles from './AddVideoStreaming.module.css'

const { videoUrl } = configurationObjectFromEnv({
  videoUrl: ENV_KEYS.VIDEO_URL,
})

interface Props {
  file: File
  videoMetadata: VideoMetaData
  isTryFree: boolean
  isPageEditor?: boolean
  onSave: ICreateOrUpdateNode
  onUpdate: (id: string, node: INodeProps) => void
}
const AddVideoStreaming = ({
  file,
  videoMetadata,
  isPageEditor,
  isTryFree,
  onSave,
  onUpdate,
}: Props) => {
  const { close } = useDialog()
  const [node, setNode] = useState<Node>()
  const [uploading, setUploading] = useState(true)

  const currentFolderId = useSelectorFactory(getCurrentFolderId)
  const dispatch = useDispatch()
  const discardChanges = useNodeDiscardChanges()
  const { t } = useTranslation()
  const { boardId } = useRouterParams()
  const { error, progress, cancelUpload, uploadFile } = useUploadFirebase()
  const { title, setTitle } = useNodeAPI('', '')
  const { cancelUploadDialog } = useAddVideoStreamingDialogs()

  useEffect(() => {
    if (node?.id) {
      dispatch(videoActions.setVideoStreamingId(node.id))
    }
  }, [dispatch, node])

  const handleSave = () => {
    if (!node) return

    onUpdate(node?.id, {
      title,
      text: node?.text,
      type: NodeType.VIDEO_STREAMING,
    })
    dispatch(videoActions.setVideoStreamingId(''))
    dispatch(videoActions.setVideoStreamingUrl(''))
    close()
  }

  const uploadSuccessfully = useCallback(
    (assetId?: string) => {
      setUploading(false)

      const text = `${videoUrl}/${assetId}.m3u8`
      dispatch(videoActions.setVideoStreamingUrl(text))

      const nodeProps = {
        title: '',
        text,
        type: NodeType.VIDEO_STREAMING,
        isProcessing: true,
        width: videoMetadata.width,
        height: videoMetadata.height,
      }

      const id = onSave(nodeProps, { try_free: isTryFree.toString() })

      if (typeof id === 'string') {
        setNode({ ...nodeProps, id })
      }
    },
    [dispatch, videoMetadata.width, videoMetadata.height, onSave, isTryFree],
  )

  const startUpload = useCallback(
    () => uploadFile(file, uploadSuccessfully),
    [file, uploadFile, uploadSuccessfully],
  )

  useEffect(() => {
    startUpload()

    return cancelUpload
  }, [startUpload, cancelUpload])

  const handleClose = () => {
    if (uploading) {
      cancelUploadDialog(() => {
        cancelUpload()
        close()
      })
      return
    }

    discardChanges()
  }

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: handleClose,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <DoneButton onClick={handleSave} disabled={uploading}>
              <Trans i18nKey="action_done" />
            </DoneButton>
          }
        >
          <div className={clsx(styles.Root, !isPageEditor && styles.WithTitle)}>
            {!isPageEditor && (
              <InputModalTitle
                value={title}
                placeholder={t('add_an_update_title_optional')}
                onChange={setTitle}
              />
            )}
            {uploading ? (
              <div className={styles.Center}>
                <div className={styles.ProgressBarContainer}>
                  <Grid justifyContent="space-between">
                    <span className={styles.UploadingLabel}>
                      {error ? (
                        <Trans i18nKey={'upload_failed_please_try_again'} />
                      ) : (
                        <Trans i18nKey={'label_uploading'} />
                      )}
                    </span>
                    {error ? (
                      <RetryIcon
                        onClick={startUpload}
                        className={styles.RetryIcon}
                      />
                    ) : (
                      <SpinnerIcon className={styles.SpinnerIcon} />
                    )}
                  </Grid>
                  <ProgressBar progress={progress} isRed={error} />
                </div>
              </div>
            ) : (
              node && (
                <VideoStreaming
                  boardId={boardId}
                  folderId={currentFolderId}
                  nodeId={node.id}
                  showDescriptionLabel
                />
              )
            )}
          </div>
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default AddVideoStreaming
