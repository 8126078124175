import {
  Icon,
  LazyImage,
  PdfForPageIcon,
  SpinnerSize,
  SpinnerJustify,
} from 'boards-web-ui'

import styles from './NodePdf.module.css'

interface Props {
  title: string
  thumbnail?: string
}

const NodePdf = ({ thumbnail, title }: Props) => (
  <div className={`${styles.Root}`}>
    <div className={styles.RootContainer}>
      <div className={styles.ImageContainer}>
        <LazyImage
          src={thumbnail || ''}
          alt={title}
          className={styles.Image}
          spinnerStyleProps={{
            size: SpinnerSize.SMALL,
            justify: SpinnerJustify.START,
          }}
        />
      </div>
      {thumbnail ? (
        <Icon className={styles.PdfIcon}>
          <PdfForPageIcon width={25} height={17} />
        </Icon>
      ) : null}
    </div>
  </div>
)

export default NodePdf
