export function getVimeoVideoInfo(videoId) {
  const url = `https://vimeo.com/api/v2/video/${videoId}.json`

  return fetch(url)
    .then((response) => response.json())
    .then((info) => {
      if (info.length > 0) {
        return {
          title: info[0].title,
          thumbnail: info[0].thumbnail_large,
        }
      }
      return null
    })
}
