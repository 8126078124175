import { AnimatedDialog, useDialog } from 'boards-web-ui'
import { Trans, useTranslation } from 'react-i18next'

import InputModalTitle from 'elements/InputModalTitle'
import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'
import DoneButton from 'ui/components/DoneButton'
import ModalEditWithConfirmation from 'components/ModalEditWithConfirmation'

import { Node, NodeType } from '../../../models'
import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'
import useNodeAPI from '../hooks/useNodeAPI'
import useNodeDiscardChanges from '../hooks/useNodeDiscardChanges'

import VideoStreaming from './VideoStreaming'

interface Props {
  node: Node
  boardId: string
  folderId: string
  isPageEditor?: boolean
  onSave: ICreateOrUpdateNode
}
const EditVideoStreaming = ({
  node,
  boardId,
  folderId,

  isPageEditor,
  onSave,
}: Props) => {
  const { t } = useTranslation()
  const { close } = useDialog()
  const discardChanges = useNodeDiscardChanges()

  const { title, hasChanges, setTitle } = useNodeAPI(node?.title || '', '')

  const handleSave = () => {
    onSave({ title, text: node?.text, type: NodeType.VIDEO_STREAMING })
    close()
  }

  const handleClose = () => {
    if (hasChanges) {
      discardChanges()
      return
    }

    close()
  }

  if (!node) return null

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: handleSave,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <DoneButton onClick={handleSave}>
              <Trans i18nKey="action_done" />
            </DoneButton>
          }
        >
          {!isPageEditor && (
            <InputModalTitle
              value={title}
              placeholder={t('add_an_update_title_optional')}
              onChange={setTitle}
            />
          )}

          <VideoStreaming
            boardId={boardId}
            folderId={folderId}
            nodeId={node.id}
          />
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default EditVideoStreaming
