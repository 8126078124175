import {
  ProfilingQuestionKeysEnum,
  ProfilingQuestionReportKeys,
  ProfilingQuestionReportValues,
  ProfilingSubmitQAndA,
} from '@features/profiling/models'
import {
  TeamSizeAnswersByRankArbonne,
  TeamSizeAnswersByRankColorStreet,
  TeamSizeAnswersByRankDoTerra,
  TeamSizeAnswersByRankFarmasi,
  TeamSizeAnswersByRankHerbalife,
  TeamSizeAnswersByRankItWorks,
  TeamSizeAnswersByRankJuicePlus,
  TeamSizeAnswersByRankLimelife,
  TeamSizeAnswersByRankMaryKay,
  TeamSizeAnswersByRankMelaleuca,
  TeamSizeAnswersByRankModere,
  TeamSizeAnswersByRankMonat,
  TeamSizeAnswersByRankNuSkin,
  TeamSizeAnswersByRankOriflame,
  TeamSizeAnswersByRankPamperedChef,
  TeamSizeAnswersByRankPruvit,
  TeamSizeAnswersByRankPureRomance,
  TeamSizeAnswersByRankScentsy,
  TeamSizeAnswersByRankSeneGence,
  TeamSizeAnswersByRankThirtyOneGifts,
  TeamSizeAnswersByRankTranont,
  TeamSizeAnswersByRankTupeerware,
  TeamSizeAnswersByRankXyngular,
  TeamSizeAnswersByRankYounique,
  TimeActiveAnswersByRankMaryKay,
  TimeAnswersByRankArbonne,
  TimeAnswersByRankColorStreet,
  TimeAnswersByRankDoTerra,
  TimeAnswersByRankFarmasi,
  TimeAnswersByRankHerbalife,
  TimeAnswersByRankItWorks,
  TimeAnswersByRankJuicePlus,
  TimeAnswersByRankLimelife,
  TimeAnswersByRankMelaleuca,
  TimeAnswersByRankModere,
  TimeAnswersByRankMonat,
  TimeAnswersByRankNuSkin,
  TimeAnswersByRankOriflame,
  TimeAnswersByRankPamperedChef,
  TimeAnswersByRankPruvit,
  TimeAnswersByRankPureRomance,
  TimeAnswersByRankScentsy,
  TimeAnswersByRankSeneGence,
  TimeAnswersByRankThirtyOneGifts,
  TimeAnswersByRankTranont,
  TimeAnswersByRankTupeerware,
  TimeAnswersByRankXyngular,
  TimeAnswersByRankYounique,
} from '@features/profiling/data/profilingAnswers'

const SUBMIT_DATA_TO_ANALYTICS_KEYS_MAPPING: { [key: string]: string } = {
  [ProfilingQuestionReportValues.industry]: 'industry',
  [ProfilingQuestionReportValues.brandName]: 'brand',
  [ProfilingQuestionReportValues.userType]: 'type',
  [ProfilingQuestionReportValues.teamSize]: 'team_size',
  [ProfilingQuestionReportValues.timeInBusiness]: 'business_active_time',
  [ProfilingQuestionReportValues.profilingRank]: 'profiling_rank',
}

const RANK_QUESTION_KEYS = [
  ProfilingQuestionKeysEnum.PROFILING_RANK_HERBALIFE,
  ProfilingQuestionKeysEnum.PROFILING_RANK_FARMASI,
  ProfilingQuestionKeysEnum.PROFILING_RANK_YOUNIQUE,
  ProfilingQuestionKeysEnum.PROFILING_RANK_ARBONNE,
  ProfilingQuestionKeysEnum.PROFILING_RANK_NU_SKIN,
  ProfilingQuestionKeysEnum.PROFILING_RANK_IT_WORKS,
  ProfilingQuestionKeysEnum.PROFILING_RANK_MODERE,
  ProfilingQuestionKeysEnum.PROFILING_RANK_JUICE_PLUS,
  ProfilingQuestionKeysEnum.PROFILING_RANK_PAMPERED_CHEF,
  ProfilingQuestionKeysEnum.PROFILING_RANK_XYNGULAR,
  ProfilingQuestionKeysEnum.PROFILING_RANK_ORIFLAME,
  ProfilingQuestionKeysEnum.PROFILING_RANK_PRUVIT,
  ProfilingQuestionKeysEnum.PROFILING_RANK_MARY_KAY,
  ProfilingQuestionKeysEnum.PROFILING_RANK_SENE_GENCE,
  ProfilingQuestionKeysEnum.PROFILING_RANK_MONAT,
  ProfilingQuestionKeysEnum.PROFILING_RANK_DO_TERRA,
  ProfilingQuestionKeysEnum.PROFILING_RANK_LIMELIFE,
  ProfilingQuestionKeysEnum.PROFILING_RANK_MELALEUCA,
  ProfilingQuestionKeysEnum.PROFILING_RANK_SCENTSY,
  ProfilingQuestionKeysEnum.PROFILING_RANK_PURE_ROMANCE,
  ProfilingQuestionKeysEnum.PROFILING_RANK_TRANONT,
  ProfilingQuestionKeysEnum.PROFILING_RANK_TUPEERWARE,
  ProfilingQuestionKeysEnum.PROFILING_RANK_COLOR_STREET,
  ProfilingQuestionKeysEnum.PROFILING_RANK_THIRTY_ONE_GIFTS,
]

export const findIsRankQuestion = (question: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (RANK_QUESTION_KEYS as any).includes(question)
}

export const findHasRankQuestion = (submitData: ProfilingSubmitQAndA) => {
  return Object.keys(submitData).find((question) => {
    return !!findIsRankQuestion(question)
  })
}

export const findRankQuestionAndAnswer = (
  submitData: ProfilingSubmitQAndA,
): { teamSize: string; timeInBusiness: string } | null => {
  const rankQuestion = findHasRankQuestion(submitData)
  if (rankQuestion) {
    return getTeamSizeAndTimeInBusinessByRank({
      rankQuestion,
      rankAnswer: submitData[rankQuestion],
    })
  }
  return null
}

export const getTeamSizeAndTimeInBusinessByRank = ({
  rankQuestion,
  rankAnswer,
}: {
  rankQuestion: string
  rankAnswer: string
}): { teamSize: string; timeInBusiness: string } => {
  switch (rankQuestion) {
    case ProfilingQuestionKeysEnum.PROFILING_RANK_HERBALIFE: {
      return {
        teamSize: TeamSizeAnswersByRankHerbalife[rankAnswer],
        timeInBusiness: TimeAnswersByRankHerbalife[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_FARMASI: {
      return {
        teamSize: TeamSizeAnswersByRankFarmasi[rankAnswer],
        timeInBusiness: TimeAnswersByRankFarmasi[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_YOUNIQUE: {
      return {
        teamSize: TeamSizeAnswersByRankYounique[rankAnswer],
        timeInBusiness: TimeAnswersByRankYounique[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_ARBONNE: {
      return {
        teamSize: TeamSizeAnswersByRankArbonne[rankAnswer],
        timeInBusiness: TimeAnswersByRankArbonne[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_NU_SKIN: {
      return {
        teamSize: TeamSizeAnswersByRankNuSkin[rankAnswer],
        timeInBusiness: TimeAnswersByRankNuSkin[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_IT_WORKS: {
      return {
        teamSize: TeamSizeAnswersByRankItWorks[rankAnswer],
        timeInBusiness: TimeAnswersByRankItWorks[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_MODERE: {
      return {
        teamSize: TeamSizeAnswersByRankModere[rankAnswer],
        timeInBusiness: TimeAnswersByRankModere[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_JUICE_PLUS: {
      return {
        teamSize: TeamSizeAnswersByRankJuicePlus[rankAnswer],
        timeInBusiness: TimeAnswersByRankJuicePlus[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_PAMPERED_CHEF: {
      return {
        teamSize: TeamSizeAnswersByRankPamperedChef[rankAnswer],
        timeInBusiness: TimeAnswersByRankPamperedChef[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_XYNGULAR: {
      return {
        teamSize: TeamSizeAnswersByRankXyngular[rankAnswer],
        timeInBusiness: TimeAnswersByRankXyngular[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_ORIFLAME: {
      return {
        teamSize: TeamSizeAnswersByRankOriflame[rankAnswer],
        timeInBusiness: TimeAnswersByRankOriflame[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_PRUVIT: {
      return {
        teamSize: TeamSizeAnswersByRankPruvit[rankAnswer],
        timeInBusiness: TimeAnswersByRankPruvit[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_MARY_KAY: {
      return {
        teamSize: TeamSizeAnswersByRankMaryKay[rankAnswer],
        timeInBusiness: TimeActiveAnswersByRankMaryKay[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_SENE_GENCE: {
      return {
        teamSize: TeamSizeAnswersByRankSeneGence[rankAnswer],
        timeInBusiness: TimeAnswersByRankSeneGence[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_MONAT: {
      return {
        teamSize: TeamSizeAnswersByRankMonat[rankAnswer],
        timeInBusiness: TimeAnswersByRankMonat[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_DO_TERRA: {
      return {
        teamSize: TeamSizeAnswersByRankDoTerra[rankAnswer],
        timeInBusiness: TimeAnswersByRankDoTerra[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_LIMELIFE: {
      return {
        teamSize: TeamSizeAnswersByRankLimelife[rankAnswer],
        timeInBusiness: TimeAnswersByRankLimelife[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_MELALEUCA: {
      return {
        teamSize: TeamSizeAnswersByRankMelaleuca[rankAnswer],
        timeInBusiness: TimeAnswersByRankMelaleuca[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_SCENTSY: {
      return {
        teamSize: TeamSizeAnswersByRankScentsy[rankAnswer],
        timeInBusiness: TimeAnswersByRankScentsy[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_PURE_ROMANCE: {
      return {
        teamSize: TeamSizeAnswersByRankPureRomance[rankAnswer],
        timeInBusiness: TimeAnswersByRankPureRomance[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_TRANONT: {
      return {
        teamSize: TeamSizeAnswersByRankTranont[rankAnswer],
        timeInBusiness: TimeAnswersByRankTranont[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_TUPEERWARE: {
      return {
        teamSize: TeamSizeAnswersByRankTupeerware[rankAnswer],
        timeInBusiness: TimeAnswersByRankTupeerware[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_COLOR_STREET: {
      return {
        teamSize: TeamSizeAnswersByRankColorStreet[rankAnswer],
        timeInBusiness: TimeAnswersByRankColorStreet[rankAnswer],
      }
    }
    case ProfilingQuestionKeysEnum.PROFILING_RANK_THIRTY_ONE_GIFTS: {
      return {
        teamSize: TeamSizeAnswersByRankThirtyOneGifts[rankAnswer],
        timeInBusiness: TimeAnswersByRankThirtyOneGifts[rankAnswer],
      }
    }
    default: {
      return {
        teamSize: '',
        timeInBusiness: '',
      }
    }
  }
}

export const convertSubmitDataToMarketingData = (
  submitData: ProfilingSubmitQAndA,
) => {
  const submitDataClone = { ...submitData }
  const rankAnswer = findRankQuestionAndAnswer(submitDataClone)
  if (rankAnswer) {
    submitDataClone[ProfilingQuestionKeysEnum.TEAM_SIZE] = rankAnswer.teamSize
    submitDataClone[ProfilingQuestionKeysEnum.TIME_IN_BUSINESS] =
      rankAnswer.timeInBusiness
  }
  return Object.keys(submitDataClone).reduce(
    (acc: ProfilingSubmitQAndA, key: string) => {
      acc[ProfilingQuestionReportKeys[key as ProfilingQuestionKeysEnum]] =
        submitDataClone[key]
      return acc
    },
    {},
  )
}

export const getUserPropertiesForAnalytics = (
  submitData: ProfilingSubmitQAndA,
) => {
  const result = Object.keys(submitData).reduce(
    (acc: ProfilingSubmitQAndA, key: string) => {
      const newKey = SUBMIT_DATA_TO_ANALYTICS_KEYS_MAPPING[key]
      acc[newKey] = submitData[key]
      return acc
    },
    {},
  )
  if (!('profiling_rank' in result)) {
    return {
      ...result,
      profiling_rank: null,
    }
  }
  return result
}
