import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './InputCheckbox.module.css'

const InputCheckbox = React.forwardRef((props, ref) => {
  const { t } = useTranslation()
  const { children, name, errors, ...rest } = props

  const hasError = errors && errors[name]

  return (
    <>
      <label className={styles.Label}>
        <input
          ref={ref}
          type="checkbox"
          className={styles.Input}
          name={name}
          {...rest}
        />
        {children}
      </label>
      {hasError && (
        <div className={styles.Error}>{t(errors[name].message)}</div>
      )}
    </>
  )
})

export default InputCheckbox
