import { useRef } from 'react'
import PropTypes from 'prop-types'
import { useDetectOutsideClick } from '../hooks/useDetectOutsideClick'
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDown.svg'
import { ReactComponent as RightIcon } from '../icons/Right.svg'
import styles from './Select.module.css'

const Select = ({ options, value, disabled, onSelect, sx }) => {
  const elRef = useRef(null)
  const [open, setOpen] = useDetectOutsideClick(elRef, false)

  const customStyle = {}
  if (sx) {
    Object.assign(customStyle, sx)
  }

  const handleOnSelect = (e, updatedValue) => {
    e.stopPropagation()
    if (!disabled) {
      onSelect(updatedValue)
      setOpen(false)
    }
  }

  const handleOpen = () => {
    if (!disabled) {
      setOpen(true)
    }
  }

  const label = options.find((o) => o.value === value)?.label

  return (
    <div ref={elRef} className={styles.Root} disabled={disabled}>
      <button className={styles.Value} onClick={handleOpen} style={customStyle}>
        {label}
        <ArrowDownIcon className={styles.ArrowDownIcon} />
      </button>
      {open && (
        <div className={styles.Options}>
          {options.map((option) => (
            <div
              key={option.value}
              className={styles.Option}
              onClick={(e) => handleOnSelect(e, option.value)}
            >
              <span className={styles.OptionText}>{option.label}</span>
              {value === option.value && <RightIcon />}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

Select.defaultProps = {
  disabled: false,
}
Select.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  // eslint-disable-next-line
  sx: PropTypes.object,
  onSelect: PropTypes.func,
}

export default Select
