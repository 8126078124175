import PropTypes from 'prop-types'
import styles from './Toast.module.css'
import { ReactComponent as RightIcon } from '../icons/ToastRight.svg'

const Toast = ({ local, variant, children, className, ...rest }) => {
  const locationStyle = local ? styles.Local : styles.Global
  const classes = [styles.Root, locationStyle, className]

  return (
    <div className={classes.join(' ')} {...rest}>
      {variant === 'success' && <RightIcon className={styles.Icon} />}
      {children}
    </div>
  )
}

Toast.defaultProps = {
  local: false,
  variant: 'success',
}

Toast.propTypes = {
  local: PropTypes.bool,
  variant: PropTypes.oneOf(['success', 'error']),
}

export default Toast
