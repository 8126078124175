import { UserContentSettings } from '@models/UserContentSettings'
import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'

export const enum ACTION_TYPE {
  ADD_CONTENT = 'ADD_CONTENT',
  CREATE_CONTENT = 'CREATE_CONTENT',
  UPDATE_CONTENT_COLLECTION = 'UPDATE_CONTENT_COLLECTION',
  SET_USER_CONTENT_SETTINGS = 'SET_USER_CONTENT_SETTINGS',
}

const addContent = (
  board: string,
  folder: string,
  key: string,
  properties: INodeProps,
) => ({
  type: ACTION_TYPE.ADD_CONTENT,
  board,
  folder,
  key,
  properties,
})

const createContent = (contentId: string, userId: string) => ({
  type: ACTION_TYPE.CREATE_CONTENT,
  contentId,
  userId,
})

const updateContentCollection = (modified: string, removed: string) => ({
  type: ACTION_TYPE.UPDATE_CONTENT_COLLECTION,
  modified,
  removed,
})

const setUserContentSettings = (settings: UserContentSettings) => ({
  type: ACTION_TYPE.SET_USER_CONTENT_SETTINGS,
  settings,
})

const actions = {
  addContent,
  createContent,
  updateContentCollection,
  setUserContentSettings,
} as const

export default actions
