import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGeolocationData, IGeolocationData } from 'boards-web-ui'

import { commonActions } from '../actions'
import { AppState } from '../app/models/appState'

const geoEuropeList = [
  'AL', // Albania
  'AD', // Andorra
  'AM', // Armenia
  'AT', // Austria
  'AZ', // Azerbaijan
  'BY', // Belarus
  'BE', // Belgium
  'BA', // Bosnia and Herzegovina
  'BG', // Bulgaria
  'HR', // Croatia
  'CY', // Cyprus
  'CZ', // Czech Republic
  'DK', // Denmark
  'EE', // Estonia
  'FI', // Finland
  'FR', // France
  'GE', // Georgia
  'DE', // Germany
  'GR', // Greece
  'HU', // Hungary
  'IS', // Iceland
  'IE', // Ireland
  'IT', // Italy
  'KZ', // Kazakhstan
  'LV', // Latvia
  'LI', // Liechtenstein
  'LT', // Lithuania
  'LU', // Luxembourg
  'MK', // Macedonia
  'MT', // Malta
  'MD', // Moldova
  'MC', // Monaco
  'ME', // Montenegro
  'NL', // Netherlands
  'NO', // Norway
  'PL', // Poland
  'PT', // Portugal
  'RO', // Romania
  'RU', // Russia
  'SM', // San Marino
  'RS', // Serbia
  'SK', // Slovakia
  'SI', // Slovenia
  'ES', // Spain
  'SE', // Sweden
  'CH', // Switzerland
  'TR', // Turkey
  'UA', // Ukraine
  'GB', // United Kingdom
  'VA', // Vatican City
]

interface IUseGeoLocation {
  geoData: IGeolocationData | undefined
  loadGeoData: () => void
  isGeoFromEuropeList: boolean
  isGeoFromIsrael: boolean
}

const useGeoLocation = (): IUseGeoLocation => {
  const dispatch = useDispatch()
  const geoData = useSelector((state: AppState) => state.app.geoData)

  const { isGeoFromEuropeList, isGeoFromIsrael } = useMemo(
    () => ({
      isGeoFromEuropeList: geoData?.countryCode
        ? geoEuropeList.includes(geoData.countryCode)
        : false,
      isGeoFromIsrael: geoData?.countryCode === 'IL',
    }),
    [geoData?.countryCode],
  )

  const getDataFromIpApi = async (): Promise<IGeolocationData | undefined> => {
    try {
      const responseIP = await fetch('https://api.ipify.org/?format=json')
      const { ip } = await responseIP.json()

      const responseIpApi = await fetch(
        `https://api.ipgeolocation.io/ipgeo?apiKey=861623f6bb8140babf408338eefcf4af&ip=${ip}`,
      )

      const data = await responseIpApi.json()

      return {
        countryCode: data.country_code2,
        city: data.city,
        countryName: data.country_name,
        currency: data.currency.code,
        latitude: data.latitude,
        longitude: data.longitude,
        dialCode: data.calling_code.replace('+', ''),
      }
    } catch {
      return undefined
    }
  }

  const loadGeoData = useCallback(async () => {
    try {
      const ipApiData = await getDataFromIpApi()
      if (!ipApiData) {
        const data = await fetchGeolocationData()

        dispatch(commonActions.setGeoData(data))
      } else {
        dispatch(commonActions.setGeoData(ipApiData))
      }
    } catch (error) {
      dispatch(
        commonActions.setGeoData({
          dialCode: '1',
          countryCode: 'US',
          currency: 'USD',
        }),
      )
    }
  }, [dispatch])

  return {
    geoData,
    loadGeoData,
    isGeoFromEuropeList,
    isGeoFromIsrael,
  }
}

export default useGeoLocation
