import { useEffect, useMemo, useState, useCallback } from 'react'
import { TPageNodeProfile, NodeType } from 'boards-web-ui'

import useNodeDiscardChanges from '@features/nodeEditor/hooks/useNodeDiscardChanges'

import { isEqualObjects } from '../../../../utils/isEqual'

import {
  ImageUploadProvider,
  PageFormChangeHandler,
  PageProfileFormErrorsState,
  TPageProfileNodeProfile,
} from '../models'

const defaultState: TPageNodeProfile = {
  type: NodeType.PROFILE,
  profile: {
    image: '',
    name: '',
    title: '',
    company: '',
  },
}
type UsePageProfileFormProps = {
  initialState?: TPageNodeProfile
  imageUploadProvider: ImageUploadProvider
  onSubmitHandler: (pageProfile: TPageNodeProfile) => void
  onCancelHandler: () => void
}
const usePageProfileForm = ({
  initialState = defaultState,
  imageUploadProvider,
  onSubmitHandler,
  onCancelHandler,
}: UsePageProfileFormProps) => {
  const discardChanges = useNodeDiscardChanges()
  const [formState, setFormState] = useState<TPageNodeProfile>(initialState)
  const [pending, setPending] = useState<boolean>(false)
  const [errors, setErrors] = useState<PageProfileFormErrorsState>({})
  const isValid = useMemo(() => !Object.keys(errors).length, [errors])

  const onValidate = useCallback(
    (
      name: keyof TPageProfileNodeProfile,
      value: string | File,
      required?: boolean,
    ) => {
      if (required && !value) {
        setErrors((prevState) => ({
          ...prevState,
          [name]: `Field ${name} is required`,
        }))
      }

      if (required && value) {
        setErrors((prevState) => {
          const newErrorState = { ...prevState }
          delete newErrorState[name as keyof TPageProfileNodeProfile]
          return newErrorState
        })
      }
    },
    [setErrors],
  )

  useEffect(() => {
    Object.entries(formState?.profile).forEach(([key, value]) => {
      onValidate(
        key as keyof TPageProfileNodeProfile,
        value,
        key === 'image' || key === 'name',
      )
    })
  }, [formState, onValidate])

  useEffect(() => {
    setFormState(initialState)
  }, [initialState])

  const updateFormField = (updatedValues: Partial<TPageNodeProfile>) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      profile: {
        ...prevFormState.profile,
        ...updatedValues,
      },
    }))
  }

  const updateFormImage = async (key: string, imageFile: File) => {
    setPending(true)
    const image = await imageUploadProvider(imageFile)
    updateFormField({ [key]: image })
    setPending(false)
  }

  const onChange: PageFormChangeHandler = (e) => {
    const { name, value } = e.target

    if (typeof value === 'string') {
      updateFormField({ [name]: value })
    }

    if (value instanceof File) {
      updateFormImage(name, value)
    }
  }

  const onSubmit = () => {
    if (pending) {
      return
    }

    if (!isValid) {
      return
    }

    onSubmitHandler(formState)
  }

  const onCancel = () => {
    return !isEqualObjects(initialState, formState)
      ? discardChanges(onCancelHandler)
      : onCancelHandler()
  }

  return {
    isValid,
    formState,
    pending,
    errors,
    onChange,
    onCancel,
    onSubmit,
  } as const
}

export default usePageProfileForm
