import { ContentPickersEnum } from '@features/pickers'
import { Board } from '../app/models'

export const enum ACTION_TYPE {
  CREATE_BOARD = 'CREATE_BOARD',
  CREATE_BOARD_FAKE = 'CREATE_BOARD_FAKE',
  SET_BOARD = 'SET_BOARD',
  SET_FOLDER_PATH = 'SET_FOLDER_PATH',
  LEAVE_BOARD = 'LEAVE_BOARD',
  ACTIVATE_BOARD = 'ACTIVATE_BOARD',
  DELETE_BOARD = 'DELETE_BOARD',
  JOIN_SHARED_BOARD = 'JOIN_SHARED_BOARD',
  REMOVE_RECEIVED_DOCUMENT = 'REMOVE_RECEIVED_DOCUMENT',
}

const createBoardAction = (
  owner: string,
  board: string,
  key: string,
  properties: { title?: string; icon?: string },
  picker?: ContentPickersEnum,
) => ({
  type: ACTION_TYPE.CREATE_BOARD,
  owner,
  board,
  key,
  properties,
  picker,
})

const createBoardFake = (
  owner: string,
  board: string,
  key: string | undefined,
  properties: {
    icon?: string
    title?: string
  },
) => ({
  type: ACTION_TYPE.CREATE_BOARD_FAKE,
  owner,
  board,
  key,
  properties,
})

const setBoard = (boardId: string, board: Board) => ({
  type: ACTION_TYPE.SET_BOARD,
  boardId,
  board,
})

const leaveBoard = (
  board: string,
  emails: Array<string | undefined | null>,
) => ({
  type: ACTION_TYPE.LEAVE_BOARD,
  board,
  emails,
})

const setFolderPathAction = (
  picker: ContentPickersEnum,
  boardId?: string,
  folderId?: string,
) => ({
  type: ACTION_TYPE.SET_FOLDER_PATH,
  picker,
  boardId,
  folderId,
})

const activateBoard = (board: string) => ({
  type: ACTION_TYPE.ACTIVATE_BOARD,
  board,
})

const deleteBoard = (board: string) => ({
  type: ACTION_TYPE.DELETE_BOARD,
  board,
})

const joinSharedFolder = (key: string) => ({
  type: ACTION_TYPE.JOIN_SHARED_BOARD,
  key,
})

const removeBoard = (documentId: string, own?: boolean) => ({
  type: ACTION_TYPE.REMOVE_RECEIVED_DOCUMENT,
  documentId,
  own,
})

const actions = {
  createBoardAction,
  createBoardFake,
  setBoard,
  leaveBoard,
  setFolderPathAction,
  activateBoard,
  deleteBoard,
  joinSharedFolder,
  removeBoard,
} as const

export default actions
