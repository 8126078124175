import { FC } from 'react'
import DoneButton from '@ui/components/DoneButton'

import usePageProfile from '../hooks/usePageProfile'

type PageProfileDialogFooterProps = {
  name: string
}
const PageProfileDialogFooter: FC<PageProfileDialogFooterProps> = ({
  name,
}) => {
  const { pageProfileFormState } = usePageProfile()

  return (
    <DoneButton
      disabled={!pageProfileFormState.isValid}
      onClick={pageProfileFormState.onSubmit}
    >
      {name}
    </DoneButton>
  )
}
export default PageProfileDialogFooter
