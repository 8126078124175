import { Role } from '../../../models'
import { ShareOption } from '../models/ShareOption'

const { OWN, WRITE, SHARE, READ } = Role

const options: ShareOption[] = [
  { label: 'All', value: 'all', roles: [OWN, WRITE, SHARE, READ] },
  { label: 'Can Edit', value: 'write', roles: [OWN] },
  { label: 'Can View & Share', value: 'share', roles: [OWN, WRITE, SHARE] },
  { label: 'Can View Only', value: 'read', roles: [OWN, WRITE, SHARE] },
]

const useShareOptions = () => {
  const allOptionFilter = ({ value }: ShareOption) => value !== 'all'

  const shareOptionsByRole = (role: Role = READ) =>
    options.filter(allOptionFilter).filter(({ roles }) => roles.includes(role))

  const shareOptionsWithAll = options
  const shareOptions = options.filter(allOptionFilter)

  return { shareOptions, shareOptionsWithAll, shareOptionsByRole } as const
}

export default useShareOptions
