import React, { FC, useEffect } from 'react'
import { ButtonNew } from 'boards-web-ui'
import { Trans, useTranslation } from 'react-i18next'

import { useLogEvents } from '@features/analytics/useLogEvents'

import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'
import ModalWithEmojiHeader from '../../../../ui/components/ModalWithEmojiHeader'

import styles from './BoardsLimitationsAnnouncement.module.css'

type Props = {
  link: string
  limit: string
  onCancel: () => void
  onUpgrade: () => void
}
const BoardsLimitationsAnnouncement: FC<Props> = ({
  onCancel,
  onUpgrade,
  limit,
  link,
}) => {
  const { t } = useTranslation()
  const { trigger } = useLogEvents()

  useEffect(() => {
    trigger('app_boards_limit_intro_popup_view')
    // eslint-disable-next-line
  }, [])

  const onLearnMoreClick = () => {
    trigger('app_boards_limit_intro_popup_learn')
  }

  const onUpgradeClick = () => {
    trigger('app_boards_limit_intro_popup_upgrade')

    onUpgrade()
  }

  const onCancelClick = () => {
    trigger('app_boards_limit_intro_popup_dismiss')

    onCancel()
  }

  return (
    <ModalWithEmojiHeader
      emoji="📢"
      close={<CloseButtonWithTooltip onClose={onCancelClick} />}
      actions={
        <div className={styles.Actions}>
          <ButtonNew
            color="secondary"
            href={link}
            target="_blank"
            size="large"
            onClick={onLearnMoreClick}
          >
            <Trans i18nKey="limit_announcement_learn" />
          </ButtonNew>

          <ButtonNew size="large" onClick={onUpgradeClick}>
            <Trans i18nKey="limit_announcement_upgrade" />
          </ButtonNew>
        </div>
      }
    >
      <h3 className={styles.Title}>
        <Trans i18nKey="limit_announcement_title" />
      </h3>

      {t('limit_announcement_body')
        ?.replaceAll('%@', limit)
        ?.split('\n\n')
        ?.map((p) => (
          <p key={p?.trim()} className={styles.P}>
            {p}
          </p>
        ))}
    </ModalWithEmojiHeader>
  )
}

export default React.memo(BoardsLimitationsAnnouncement)
