import React, { useEffect } from 'react'
import useFolderEvents from '@features/analytics/useFolderEvents'
import useBoard from '@hooks/useBoard'

import { ScrollAreaContainer } from '@ui/models/ScrollArea'
import useScrollArea from '../../../hooks/useScrollArea'
import useBoardEvents from './hooks/useBoardEvents'
import FolderManagement from './components/FolderManagement'

import EmptyContent, {
  EmptyBoardVariants,
} from '../../../components/EmptyContent'
import useBoardMode from './hooks/useBoardMode'
import { Board as IBoard, Folder } from '../../models'

export interface BoardProps {
  boardId: string
  folder: Folder
  isFolderEmpty: boolean
  isRoot: boolean
  isSharedFolderMode?: boolean
  canBeShared?: boolean
  sharedBoardData?: IBoard
}

const Board: React.FC<BoardProps> = ({
  boardId,
  folder,
  isFolderEmpty,
  isRoot,
  isSharedFolderMode,
  canBeShared,
  sharedBoardData,
}) => {
  const mode = useBoardMode()
  const { scrollToTop } = useScrollArea()
  const { viewFolderEvent } = useFolderEvents()
  const { boardView } = useBoardEvents()
  const { board, isPageBoard, isOwnBoard, isActive } = useBoard()

  useEffect(() => {
    boardView({
      board_id: boardId,
      active_board: `${isActive}`,
    })
    // should only be trigger once when the component is first rendered
    // eslint-disable-next-line
  }, [boardId])

  useEffect(() => {
    if (boardId !== 'undefined-undefined' && !isRoot) {
      viewFolderEvent(boardId, folder?.id, isActive)
    }
  }, [folder?.id, boardId, viewFolderEvent, isActive, isRoot])

  useEffect(() => {
    scrollToTop(ScrollAreaContainer.FOLDER)
  }, [boardId, folder?.id, scrollToTop])

  if (!boardId || !folder || folder?.isPage) {
    return null
  }

  if (isFolderEmpty) {
    if (isPageBoard) {
      return <EmptyContent variant={EmptyBoardVariants.pageBoard} />
    }
    if (isRoot) {
      return <EmptyContent variant={EmptyBoardVariants.board} />
    }

    return (
      <EmptyContent
        variant={EmptyBoardVariants.folder}
        isSharedFolderMode={isSharedFolderMode}
      />
    )
  }

  const canCopyContentOfSharedFolder = !!sharedBoardData?.options?.copyAllowed

  return (
    <FolderManagement
      mode={mode}
      boardId={boardId}
      folder={folder}
      isSharedFolderMode={isSharedFolderMode}
      canBeShared={isSharedFolderMode ? false : canBeShared}
      canCopyContentOfSharedFolder={canCopyContentOfSharedFolder}
      canCopy={isOwnBoard || board?.options?.copyAllowed}
      isActive={isSharedFolderMode ? false : isActive}
      isPageBoard={isSharedFolderMode ? false : isPageBoard}
    />
  )
}

export default React.memo(Board)
