import { dateHelper } from 'boards-web-ui'
import { useTranslation } from 'react-i18next'

import { Node } from '../../../../models'
import { ReactComponent as AudioIcon } from '../../icons/Audio.svg'
import NodeMargin from './NodeMargin'

import styles from './NodeAudio.module.css'

interface Props {
  node: Node
  className?: string
}

/** @deprecated Prepare migration instead for "Node" component in "boards-web-ui" */
const NodeAudio = ({ node, className }: Props) => {
  const { t } = useTranslation()

  return (
    <div className={[styles.Root, className].join(' ')}>
      <NodeMargin>
        <div>
          <div className={styles.Title}>
            {node.title || t('label_my_recording')}
          </div>
          <div className={styles.Duration}>
            {dateHelper.formatSecondsToMinutes(node.duration)}
          </div>
          <AudioIcon color="#5DABFF" className={styles.Icon} />
        </div>
      </NodeMargin>
    </div>
  )
}

export default NodeAudio
