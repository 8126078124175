import React, { FC, memo, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
  ButtonNew,
  Card,
  CardActions,
  CardBody,
  CardBodyContent,
  CardHeader,
  Icon,
  InfoWithBackgroundIcon,
  theme,
} from 'boards-web-ui'

import styles from './LimitedByOwnerWarning.module.css'

type LimitedByOwnerWarningProps = {
  icon?: ReactElement
  title?: string
  submitTitle?: string
  className?: string
  onSubmit: () => void
}
const LimitedByOwnerWarning: FC<LimitedByOwnerWarningProps> = ({
  title = 'forms_action_limited_to_boards_owner',
  submitTitle = 'action_got_it',
  className,
  onSubmit,
  icon = (
    <Icon size="80px" className={styles.Icon}>
      <InfoWithBackgroundIcon />
    </Icon>
  ),
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Card {...rest} className={clsx(styles.Root, className)}>
      <CardHeader bg={theme.theme.colors.colorBlue100} icon={icon} />
      <CardBody>
        <CardBodyContent className={styles.Text}>{t(title)}</CardBodyContent>
        <CardActions>
          <ButtonNew fullWidth color="primary" size="large" onClick={onSubmit}>
            {t(submitTitle)}
          </ButtonNew>
        </CardActions>
      </CardBody>
    </Card>
  )
}

export default memo(LimitedByOwnerWarning)
