import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import {
  ButtonNew,
  CardActions,
  CardBody,
  CardBodyContent,
  CardHeader,
  useDialog,
} from 'boards-web-ui'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import useReverseTrialEvents from '@features/reverseTrial/hooks/useReverseTrialEvents'
import styles from './ReverseTrialStartv2.module.css'

const ReverseTrialStartV2 = () => {
  const { open } = useDialog()

  useEffect(() => {
    open(<ReverseTrialStartDialogV2 />, {
      fullScreen: true,
      overlayBackgroundColor: 'rgba(0, 0, 0, 0.7)',
      closeOnEscapeButton: false,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return null
}

const ReverseTrialStartDialogV2 = () => {
  const { trialDaysAvailable, handleStartReverseTrial } =
    useReverseTrialContext()
  const { startTrialViewEvent, startTrialClickEvent } = useReverseTrialEvents()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    startTrialViewEvent(trialDaysAvailable || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpgradeClick = () => {
    startTrialClickEvent({
      trialDaysAvailable: trialDaysAvailable || 0,
      trialDaysLeft: trialDaysAvailable || 0,
      isReverseTrial: true,
    })
    setIsLoading(true)
    handleStartReverseTrial()
  }

  return (
    <div className={styles.Root}>
      <CardHeader className={styles.IconContainer} icon={<>🎉</>} />

      <CardBody className={styles.Container}>
        <CardBodyContent className={styles.Content}>
          <div className={styles.Title}>
            <Trans i18nKey="reverse_start_existing_popup_title" />
          </div>

          <div className={styles.Message}>
            <Trans
              i18nKey="reverse_start_existing_popup_body1"
              values={{ day: trialDaysAvailable }}
              components={{ strong: <span style={{ fontWeight: '500' }} /> }}
            />
          </div>

          <div className={styles.Message}>
            <Trans i18nKey="reverse_start_existing_popup_body2" />
          </div>
        </CardBodyContent>

        <CardActions className={styles.Actions}>
          <ButtonNew
            fullWidth
            isLoading={isLoading}
            size={'large'}
            onClick={handleUpgradeClick}
          >
            <Trans i18nKey="action_get_started" />
          </ButtonNew>
        </CardActions>
      </CardBody>
    </div>
  )
}

export default ReverseTrialStartV2
