import { PricePreviewResponse } from '@paddle/paddle-js'
import { getCurrencySymbol } from 'boards-web-ui'

import { Subscription } from 'app/models'
import { TrialPeriodItem } from '../models/PricePreview'
import { formatNumberWithMaxTwoDecimals } from './price'

export interface ProductData {
  productName: string
  priceId: string
  price: number
  annualPrice: number
  trialPeriod: TrialPeriodItem
  subcription: Subscription
}
export interface ProductsData {
  productId: string
  month?: ProductData
  year?: ProductData
}
export interface ProductsResponse {
  currencySymbol: string
  currencyCode: string
  items: ProductsData[]
}

const calculatePrice = (value: string, isYearly: boolean) => {
  const parsedValue = Number.parseFloat(value)

  if (isYearly) {
    return formatNumberWithMaxTwoDecimals(parsedValue / 100 / 12)
  }

  return formatNumberWithMaxTwoDecimals(parsedValue / 100)
}

const transformformPricePreview = (
  subscriptions: Subscription[],
  pricePreview?: PricePreviewResponse,
): ProductsResponse | null => {
  if (!pricePreview) return null

  const products = pricePreview.data.details.lineItems.reduce(
    (acc, item) => {
      if (!item?.price?.billingCycle?.interval) return acc
      return {
        ...acc,
        [item.product.id]: {
          ...acc[item.product.id],
          productId: item.product.id,

          [item.price.billingCycle.interval]: {
            subcription: subscriptions.find(
              (s) => s.productId === item.price.id,
            ),
            productName: item.product.name,
            priceId: item.price.id,
            price: calculatePrice(
              item.totals.total,
              item.price.billingCycle.interval === 'year',
            ),
            annualPrice: formatNumberWithMaxTwoDecimals(
              Number.parseFloat(item.totals.total) / 100,
            ),
            trialPeriod: item.price.trialPeriod,
          } as ProductData,
        } as ProductsData,
      }
    },
    {} as { [index: string]: ProductsData },
  )

  return {
    currencyCode: pricePreview.data.currencyCode,
    currencySymbol: getCurrencySymbol(pricePreview.data.currencyCode),
    items: Object.values(products),
  }
}

export default transformformPricePreview
