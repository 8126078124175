import { useEffect, useMemo, useState } from 'react'
import { useDialog } from 'boards-web-ui'

import styles from '@features/checkout/components/CheckoutProviderWarning.module.css'
import LimitedByOwnerWarning from '@features/forms/components/LimitedByOwnerWarning'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import useFormsEvents, {
  GOOGLE_SHEETS_INTEGRATION_SCREEN,
} from '@features/analytics/useFormsEvents'

import { FormIntegrationStatusData } from '../../../../_firebase'

type Props = {
  isOwn: boolean
  screen: GOOGLE_SHEETS_INTEGRATION_SCREEN
  initialSpreadsheetURL?: string
  formIntegrationStatusState: FormIntegrationStatusData | null
  onGetFormIntegrationStatus: () => void
}
const useGoogleSheetConnector = ({
  isOwn,
  screen,
  initialSpreadsheetURL,
  formIntegrationStatusState,
  onGetFormIntegrationStatus,
}: Props) => {
  const { open, close } = useDialog()
  const { appFormGoogleSheetsIntegrationClick } = useFormsEvents()
  const { isGoogleAccountConnected } = useProfileInformation()

  const [spreadsheetURL, setSpreadsheetURL] = useState<string>(
    initialSpreadsheetURL ||
      formIntegrationStatusState?.decryptedProperties?.spreadsheetURL ||
      '',
  )
  const [isGoogleAccountConnectedPending, setIsGoogleAccountConnectedPending] =
    useState<boolean>(false)

  const isGoogleFormConnected: boolean = useMemo(() => {
    return (
      !!initialSpreadsheetURL ||
      (formIntegrationStatusState?.googleAuthorization.status ===
        'authorized' &&
        !!spreadsheetURL)
    )
  }, [
    formIntegrationStatusState?.googleAuthorization.status,
    spreadsheetURL,
    initialSpreadsheetURL,
  ])

  const onFormConnectToGoogleSpreadSheet = () => {
    appFormGoogleSheetsIntegrationClick(screen, isGoogleAccountConnected)

    // If it is co-editor then show decline popup;
    if (!isOwn) {
      open(<LimitedByOwnerWarning onSubmit={close} />, {
        className: styles.Clear,
        withFullScreenDialogContainer: true,
      })
      return
    }

    // If google account already have been connected just
    // update the spreadSheetURL to default and URL will be generated after save api call;
    if (
      formIntegrationStatusState?.googleAuthorization.status === 'authorized' &&
      spreadsheetURL !== 'default'
    ) {
      setSpreadsheetURL('default')
      return
    }

    // IF google account was not connected then open
    // GoogleSheet integration window and waiting for updates in useEffect hook
    if (
      formIntegrationStatusState?.googleAuthorization.status !== 'authorized' &&
      formIntegrationStatusState?.googleAuthorization.authorizationURL
    ) {
      setIsGoogleAccountConnectedPending(true)

      const windowRef = window.open(
        formIntegrationStatusState?.googleAuthorization.authorizationURL,
      )
      const timer = setInterval(() => {
        if (!windowRef || windowRef.closed) {
          onGetFormIntegrationStatus()
          clearInterval(timer)
        }
      }, 3000)
    }
  }

  useEffect(() => {
    if (formIntegrationStatusState?.decryptedProperties?.spreadsheetURL) {
      setSpreadsheetURL(
        formIntegrationStatusState?.decryptedProperties?.spreadsheetURL,
      )
    }
  }, [formIntegrationStatusState?.decryptedProperties?.spreadsheetURL])

  useEffect(() => {
    if (
      isGoogleAccountConnectedPending &&
      formIntegrationStatusState?.googleAuthorization.status === 'authorized' &&
      !spreadsheetURL &&
      spreadsheetURL !== 'default'
    ) {
      setSpreadsheetURL('default')
      setIsGoogleAccountConnectedPending(false)
    }
  }, [
    isGoogleAccountConnectedPending,
    formIntegrationStatusState?.googleAuthorization.status,
    spreadsheetURL,
  ])

  const onUnlinkSpreadSheet = () => {
    // TODO will be implemented in next releases
    // open()
  }

  return {
    isGoogleFormConnected,
    isGoogleAccountConnectedPending,
    onFormConnectToGoogleSpreadSheet,
    spreadsheetURL,
    onUnlinkSpreadSheet,
  } as const
}

export default useGoogleSheetConnector
