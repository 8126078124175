import React, { FC } from 'react'
import {
  AddSmallIcon,
  ButtonIcon,
  ButtonNew,
  Icon,
  PremiumCrownIcon,
  Tooltip,
  TooltipContent,
} from 'boards-web-ui'

import useUserFeatures, { Features } from '@hooks/useUserFeatures'
import useCreateBoard from '../hooks/useCreateBoard'
import { useBoardsMenuTranslations } from '../hooks/useBoardsMenuTranslations'

import styles from './CreateBoardButton.module.css'

interface Props {
  rounded?: boolean
  onCreate?: () => void
}
const CreateBoardButton: FC<Props> = ({ rounded, onCreate }) => {
  const createBoard = useCreateBoard()
  const { limitReached } = useUserFeatures(Features.boards)
  const { createBoard: createBoardMsg, createBoardHover } =
    useBoardsMenuTranslations()

  const onCreateBoard = () => createBoard(onCreate)

  if (rounded) {
    return (
      <Tooltip
        data={
          <TooltipContent className={styles.Animated}>
            {createBoardHover()}
          </TooltipContent>
        }
      >
        <ButtonIcon
          round
          variant="blue"
          size={34}
          onClick={onCreateBoard}
          className={styles.Animated}
        >
          <Icon size="16px" color="inherit">
            <AddSmallIcon />
          </Icon>
        </ButtonIcon>
      </Tooltip>
    )
  }

  return (
    <ButtonNew
      color={'secondary'}
      fullWidth
      size={'medium'}
      onClick={onCreateBoard}
      startIcon={
        limitReached ? (
          <PremiumCrownIcon fontSize={20} />
        ) : (
          <AddSmallIcon fontSize={16} />
        )
      }
    >
      {createBoardMsg()}
    </ButtonNew>
  )
}

export default React.memo(CreateBoardButton)
