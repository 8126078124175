import { callFirebaseFunction } from './utils'
import { contentFunctions as functions } from './functions'

export interface ICopyNodesParams {
  targetBoardId: string
  targetFolderId: string
  nodes: string[]
  key: string
  fromParams: {
    fromBoardId: string
    fromFolderId: string
    fromShortcutId?: string
  }
}

export const copyNodesApiCall = ({
  targetBoardId,
  targetFolderId,
  nodes,
  key,
  fromParams: { fromBoardId, fromFolderId, fromShortcutId },
}: ICopyNodesParams) => {
  const args = {
    board: targetBoardId,
    folder: targetFolderId,
    nodes,
    key,
    ...(fromShortcutId
      ? { fromShortcut: fromShortcutId }
      : { fromFolder: fromFolderId, fromBoard: fromBoardId }),
  }

  return callFirebaseFunction(functions, 'copyNodes', args)
}
