interface IColors {
  blue100: string
  blue200: string
  blue700: string
  blue1000: string
  gray100: string
  gray600: string
  gray700: string
}

export const colors: IColors = {
  blue100: '#F0F7FF',
  blue200: '#DEEEFF',
  blue700: '#0070E8',
  blue1000: '#002246',
  gray100: '#F8F8F8',
  gray600: '#888888',
  gray700: '#666666',
}
