import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AnimatedDialog, NODE_LINK_TYPES, useDialog } from 'boards-web-ui'

import DoneButton from 'ui/components/DoneButton'
import CloseButtonWithTooltip from 'ui/components/CloseButtonWithTooltip'
import ModalEditWithConfirmation from '../../../../components/ModalEditWithConfirmation'

import { ICreateOrUpdateNode } from '../models/IUseNodeCreatorAPI'
import { ICountry, Node, NodeType } from '../../../models'

import {
  buildText,
  getNumberFromText,
  getTextFromUrl,
  isLink,
  isPhoneInput,
} from '../../contactButtons/util'

import useNodeAPI from '../hooks/useNodeAPI'
import useNodeDiscardChanges from '../hooks/useNodeDiscardChanges'
import useButtonEvents from '../../analytics/useButtonEvents'
import useButtonValidations from '../../contactButtons/hooks/useButtonValidations'
import useButtonTranslations from '../../contactButtons/hooks/useButtonTranslations'
import EditPhoneButtonForm from '../../contactButtons/EditPhoneButtonForm'
import EditTextButtonForm from '../../contactButtons/EditTextButtonForm'
import urlHelper from '../../../../helpers/url'

import { AppState } from '../../../models/appState'
import { buttonActions } from '../../../../actions'

import styles from './EditButton.module.css'

interface Props {
  node: Node
  onSave: ICreateOrUpdateNode
}

const EditButton = ({ node, onSave }: Props) => {
  const isNew = !node.id
  const type = node.link_type || NODE_LINK_TYPES.REGULAR
  const validate = useButtonValidations()
  const { close } = useDialog()
  const discardChanges = useNodeDiscardChanges()
  const { getButtonTranslations } = useButtonTranslations()
  const buttonTranslations = getButtonTranslations(type)
  const { buttonEditorView, messengerSearchClick } = useButtonEvents()
  const dispatch = useDispatch()
  const buttonError = useSelector(
    (state: AppState) => state.app.pageEditor.buttonError,
  )
  const { title, text, hasChanges, setText, setTitle } = useNodeAPI(
    node.title,
    node.text,
  )
  const [country, setCountry] = useState<ICountry>({
    countryCode: node.countryCode,
    dialCode: node.diallingCode,
  })

  useEffect(() => {
    buttonEditorView(type)
  }, [type, buttonEditorView])

  useEffect(() => {
    dispatch(buttonActions.setButtonType(type))
  }, [dispatch, type])

  useEffect(() => {
    dispatch(
      buttonActions.setButtonGeoData({
        countryCode: country?.countryCode,
        dialCode: country?.dialCode,
      }),
    )
  }, [country, dispatch])

  const handleSave = () => {
    const { isValid, message: errorMessage } = validate(text, type)

    if (!isValid) {
      dispatch(buttonActions.setButtonError(errorMessage))
      return
    }

    onSave({
      title: title || buttonTranslations.autoPopulateText,
      text: isLink(type) ? urlHelper.withHttp(text) : text,
      type: NodeType.BUTTON,
      link_type: type,
      diallingCode: country?.dialCode,
      countryCode: country?.countryCode,
    })

    close()

    // TODO reset state action
    dispatch(buttonActions.setButtonType(undefined))
    dispatch(buttonActions.setButtonError(undefined))
    dispatch(buttonActions.setButtonGeoData(undefined))
  }

  const handleTextChange = (t: string) => {
    if (type === NODE_LINK_TYPES.WHATS_APP) {
      const msg = urlHelper.getUrlParameter(text, 'text')
      const textToSave = buildText(type, t, country, msg)
      setText(textToSave)

      return
    }

    setText(buildText(type, t, country))
  }

  const handleCountryChange = (newCountry: ICountry) => {
    const number = getNumberFromText(text, type, country.dialCode)

    setText(buildText(type, number, newCountry))
    setCountry(newCountry)
  }

  const handleMessageChange = (m: string) => {
    const number = getNumberFromText(text, type, node.diallingCode)
    const textToSave = buildText(type, number, country, m)

    setText(textToSave)
  }

  const handleOnFocus = () => dispatch(buttonActions.setButtonError(undefined))

  const handleClose = () => {
    if (hasChanges) {
      discardChanges()
      return
    }

    close()
    // TODO reset state action
    dispatch(buttonActions.setButtonType(undefined))
    dispatch(buttonActions.setButtonError(undefined))
    dispatch(buttonActions.setButtonGeoData(undefined))
  }

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
        onEscape: handleClose,
        onOverlayClick: handleSave,
      }}
      dialog={
        <ModalEditWithConfirmation
          className={styles.ModalContent}
          headerControls={<CloseButtonWithTooltip onClose={handleClose} gray />}
          footerControls={
            <DoneButton onClick={handleSave}>
              <Trans i18nKey="action_done" />
            </DoneButton>
          }
          responsiveHeight
        >
          {isPhoneInput(type) ? (
            <EditPhoneButtonForm
              type={type}
              title={title}
              text={getNumberFromText(text, type, country.dialCode)}
              error={buttonError}
              message={urlHelper.getUrlParameter(text, 'text')}
              countryCode={country?.countryCode}
              onTitleChange={setTitle}
              onChangeCountry={handleCountryChange}
              onChangeNumber={handleTextChange}
              onMessageChange={
                type === NODE_LINK_TYPES.WHATS_APP
                  ? handleMessageChange
                  : undefined
              }
              onTextFocus={handleOnFocus}
              autoFocusTitle={!isNew}
              autoFocusText={isNew}
            />
          ) : (
            <EditTextButtonForm
              type={type}
              title={title}
              text={getTextFromUrl(text, type, country.dialCode)}
              error={buttonError}
              onTitleChange={setTitle}
              onTextChange={handleTextChange}
              onTextFocus={handleOnFocus}
              autoFocusTitle={!isNew}
              autoFocusText={isNew}
            >
              {type === NODE_LINK_TYPES.FACEBOOK && (
                <a
                  className={styles.Link}
                  href="https://www.google.com/search?q=how+to+find+my+facebook+messenger+username"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Find username"
                  onClick={messengerSearchClick}
                >
                  <Trans i18nKey={'button_messenger_find_username'} />
                </a>
              )}
            </EditTextButtonForm>
          )}
        </ModalEditWithConfirmation>
      }
    />
  )
}

export default EditButton
