import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
  ButtonNew,
  Icon,
  ReverseTrialCollaborateIcon,
  ReverseTrialOrganizeIcon,
  useDialog,
} from 'boards-web-ui'
import useReverseTrialEvents from '@features/reverseTrial/hooks/useReverseTrialEvents'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import styles from './ReverseTrialStart.module.css'

const ReverseTrialStart = () => {
  const { open } = useDialog()

  useEffect(() => {
    open(<ReverseTrialStartDialog />, {
      fullScreen: true,
      overlayBackgroundColor: 'rgba(0, 0, 0, 0.7)',
      closeOnEscapeButton: false,
    })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return null
}

const ReverseTrialStartDialog = () => {
  const { trialDaysAvailable, handleStartReverseTrial } =
    useReverseTrialContext()
  const { startTrialViewEvent, startTrialClickEvent } = useReverseTrialEvents()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const bulletPoints = [
    {
      icon: <ReverseTrialOrganizeIcon />,
      title: t('start_trial_item_1_title'),
      body: t('start_trial_item_1_subtitle'),
    },
    {
      icon: <ReverseTrialOrganizeIcon />,
      title: t('start_trial_item_3_title'),
      body: t('start_trial_item_3_subtitle'),
      isCenterAligned: true,
    },
    {
      icon: <ReverseTrialCollaborateIcon />,
      title: t('start_trial_item_4_title'),
      body: t('start_trial_item_4_subtitle'),
      isCenterAligned: true,
    },
  ]

  useEffect(() => {
    startTrialViewEvent(trialDaysAvailable || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpgradeClick = () => {
    startTrialClickEvent({
      trialDaysAvailable: trialDaysAvailable || 0,
      trialDaysLeft: trialDaysAvailable || 0,
      isReverseTrial: true,
    })
    setLoading(true)
    handleStartReverseTrial()
  }

  return (
    <div className={styles.Root}>
      <div className={styles.HeaderCnt}>
        <div>
          <h3 className={styles.Header}>
            <Trans i18nKey="start_trial_new_users_title" />
          </h3>
        </div>

        <h4 className={styles.HeaderDescription}>
          <Trans
            i18nKey="start_trial_new_users_subtitle"
            values={{ day: trialDaysAvailable }}
            components={{
              strong: <span style={{ fontWeight: '500' }} />,
            }}
          />
        </h4>
      </div>

      <div className={styles.DetailsCnt}>
        <div className={styles.Bullets}>
          {bulletPoints.map((bullet) => (
            <div
              key={bullet.title}
              className={clsx(
                styles.Bullet,
                bullet.isCenterAligned && styles.BulletCenterAligned,
              )}
            >
              <Icon size="40px">{bullet.icon}</Icon>
              <div className={styles.BulletTexts}>
                <h5 className={styles.BulletTitle}>{bullet.title}</h5>
                <h6 className={styles.BulletBody}>{bullet.body}</h6>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.ActionCnt}>
          <h6 className={styles.Info}>
            {t('start_trial_new_users_button_note')}
          </h6>
          <div className={styles.ActionButton}>
            <ButtonNew
              isLoading={loading}
              fullWidth
              size={'medium'}
              onClick={handleUpgradeClick}
            >
              {t('start_trial_new_users_button')}
            </ButtonNew>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReverseTrialStart
