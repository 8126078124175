import { FC } from 'react'
import { Trans } from 'react-i18next'

import { ProductData } from '@features/payments/utils/productTransformer'

interface Props {
  plan: ProductData
  subscribedPlanId?: string
  showCurrentPlanText?: boolean
}
const SubscribeButtonText: FC<Props> = ({
  subscribedPlanId,
  plan,
  showCurrentPlanText,
}) => {
  if (plan.priceId === subscribedPlanId || showCurrentPlanText) {
    return <Trans i18nKey={'paywall_current_plan'} />
  }

  if (plan?.subcription?.offer?.type === 'trial') {
    return (
      <Trans
        i18nKey={'paywall_start_free_trial_days'}
        values={{ days: plan?.subcription?.offer.days }}
      />
    )
  }

  return <Trans i18nKey={'action_subscribe'} />
}

export default SubscribeButtonText
