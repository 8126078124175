import { useEffect, useMemo, useState } from 'react'
import useFolder from '@hooks/useFolder'
import useNodeEvents from '@features/analytics/useNodeEvents'
import useLogUsageEvents from '@features/analytics/useLogUsageEvents'
import useNodeEditorAPI from '@features/nodeEditor/hooks/useNodeEditorAPI'

import { Folder, Node, NodeType } from '../../../models'
import useEditNode from '../../nodeEditor/useEditNode'
import useRouterParams from '../../../../hooks/useRouterParams'

import usePageNodeTransformer, {
  NodeWithSource,
} from '../../nodes/hooks/usePageNodeTransformer'

const ALLOWED_TYPES_IN_PREVIEW = [
  NodeType.LINK,
  NodeType.IMAGE,
  NodeType.PDF,
  NodeType.TEXT,
  NodeType.PARAGRAPH,
  NodeType.HEADLINE,
  NodeType.VIDEO,
  NodeType.VIDEO_STREAMING,
  NodeType.BUTTON,
  NodeType.PAGE,
  NodeType.AUDIO,
]

interface Props {
  node?: NodeWithSource
  edit: () => void
  prev?: () => void
  next?: () => void
}

const usePreviewNode = (
  specificNid: string,
  folder?: Folder,
  editorPopup?: boolean,
  sharedBoardId?: string,
  sharedFolderId?: string,
): Props => {
  const [nid, setNid] = useState(specificNid)
  let { boardId } = useRouterParams()
  let { folderId } = useRouterParams()
  const { folder: srcFolder } = useFolder(boardId, folder?.id)
  if (sharedBoardId && sharedFolderId) {
    boardId = sharedBoardId
    folderId = sharedFolderId
  }

  const { contentEditScreenEvent } = useNodeEvents()
  const { viewNode } = useLogUsageEvents()
  const transformPageNode = usePageNodeTransformer()
  const updateNode = useNodeEditorAPI(boardId, folderId)
  const editNode = useEditNode(boardId, folderId, updateNode)

  const nodes =
    (srcFolder || folder)?.content?.filter(({ type }) =>
      ALLOWED_TYPES_IN_PREVIEW.includes(type),
    ) || []
  const nodeSrc = nodes.find((n) => n.id === nid)
  const node = useMemo(
    () => (nodeSrc ? transformPageNode(nodeSrc as Node) : undefined),
    [nodeSrc, transformPageNode],
  )

  const nodeIndex = nodes.findIndex((n) => n.id === nid)
  const hasPrev = nodeIndex > 0
  const hasNext = nodes.length - 1 > nodeIndex

  useEffect(() => {
    setNid(specificNid)
  }, [specificNid])

  const edit = () => {
    if (node) {
      editNode(node?.source)
      contentEditScreenEvent(node.source)
    }
  }

  const prev = () => {
    if (hasPrev) {
      setNid(nodes[nodeIndex - 1].id)

      if (node?.source.type !== NodeType.FOLDER) {
        viewNode(nodes[nodeIndex - 1].id)
      }
    }
  }

  const next = () => {
    if (hasNext) {
      setNid(nodes[nodeIndex + 1].id)

      if (node?.source.type !== NodeType.FOLDER) {
        viewNode(nodes[nodeIndex + 1].id)
      }
    }
  }

  return {
    node,
    edit,
    prev: hasPrev ? prev : undefined,
    next: hasNext ? next : undefined,
  } as const
}

export default usePreviewNode
