import React, { FC, HTMLAttributes, ReactElement } from 'react'
import clsx from 'clsx'
import { ButtonIcon } from 'boards-web-ui'

import styles from './PageMenuItem.module.css'

type Props = HTMLAttributes<HTMLDivElement> & {
  icon: string | ReactElement
  title: string
  chip?: string | ReactElement
  onClick: () => void
  disabledStyleMenu?: boolean
}

const PageMenuItem: FC<Props> = ({
  icon,
  title,
  chip,
  onClick,
  disabledStyleMenu,
  ...rest
}) => (
  <div className={styles.Root} onClick={onClick} {...rest}>
    <ButtonIcon
      round
      variant="blue"
      size={38}
      className={clsx(styles.Button, disabledStyleMenu && styles.Disabled)}
    >
      {icon}
    </ButtonIcon>

    <span className={clsx(styles.Title, disabledStyleMenu && styles.Disabled)}>
      {title}
    </span>
    {chip}
  </div>
)

export default PageMenuItem
