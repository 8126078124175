import {
  BubbleDataPoint,
  Chart,
  ChartTypeRegistry,
  ScatterDataPoint,
  TooltipModel,
} from 'chart.js'
import { colors } from '../config'

interface IContext {
  chart: Chart<
    keyof ChartTypeRegistry,
    (number | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  >
  tooltip: TooltipModel<'line'>
}

const getParentElement = (
  chart: Chart<
    keyof ChartTypeRegistry,
    (number | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  >,
) => {
  const tooltipEl = chart.canvas.parentNode?.querySelector('div')

  if (tooltipEl) {
    return tooltipEl
  }

  return document.createElement('div')
}

const getOrCreateTooltip = (
  chart: Chart<
    keyof ChartTypeRegistry,
    (number | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  >,
) => {
  const tooltipEl = getParentElement(chart)

  tooltipEl.style.background = colors.blue1000
  tooltipEl.style.borderRadius = '13px'
  tooltipEl.style.color = 'white'
  tooltipEl.style.opacity = '1'
  tooltipEl.style.pointerEvents = 'none'
  tooltipEl.style.position = 'absolute'
  tooltipEl.style.transform = 'translate(-50%, 33%)'
  tooltipEl.style.transition = 'all .4s ease'
  tooltipEl.style.zIndex = '1'

  const table = document.createElement('div')
  table.setAttribute('id', 'root')

  table.style.margin = '0px'

  tooltipEl.appendChild(table)

  if (chart.canvas.parentNode) {
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

const externalTooltipHandler =
  (tooltipLabel: string) => (context: IContext) => {
    const { chart, tooltip } = context
    const tooltipEl = getOrCreateTooltip(chart)

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0'
      return
    }

    if (tooltip.body) {
      const titleLines = tooltip.title || []
      const bodyLines = tooltip.body.map((b) => b.lines)

      const containerEl = document.createElement('div')
      containerEl.style.whiteSpace = 'nowrap'

      titleLines.forEach((title: string) => {
        const dateEl: HTMLElement = document.createElement('div')
        dateEl.style.borderWidth = '0'
        dateEl.style.color = colors.blue200
        dateEl.style.fontSize = '14'
        dateEl.style.fontWeight = '400'
        dateEl.style.marginBottom = '2px'
        const text = document.createTextNode(title)
        dateEl.appendChild(text)
        containerEl.appendChild(dateEl)
      })

      bodyLines.forEach((body) => {
        const activeMembersValue = document.createTextNode(body[0])
        const activeMembersLabel = document.createTextNode(` ${tooltipLabel}`)

        const number: HTMLElement = document.createElement('span')
        number.style.fontWeight = '700'
        number.append(activeMembersValue)

        const label = document.createElement('span')
        label.style.whiteSpace = 'nowrap'
        label.append(activeMembersLabel)

        containerEl.appendChild(number)
        containerEl.appendChild(label)
      })

      const rootEl = tooltipEl.querySelector('#root')
      if (rootEl) {
        while (rootEl.firstChild) {
          rootEl.firstChild.remove()
        }

        rootEl.appendChild(containerEl)
      }
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

    tooltipEl.style.opacity = '1'
    tooltipEl.style.left = `${positionX + tooltip.caretX}px`
    tooltipEl.style.top = `${positionY + tooltip.caretY}px`
    tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`
  }

export default externalTooltipHandler
