import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { onAuthStateChanged, User } from 'firebase/auth'

import { useChromeExtensionMessageProvider } from '@features/cromeExtension'

import { commonActions } from 'actions'
import auth, { getCustomToken } from '../auth'

export const useFirebaseAuthStateChangedListener = (): {
  user: User | null
  loaded: boolean
} => {
  const [firstCheck, setFirstCheck] = useState(true)
  const [user, setUser] = useState<User | null>(null)
  const dispatch = useDispatch()

  const { onAuthorized, onUnAuthorized } = useChromeExtensionMessageProvider()

  useEffect(() => {
    return onAuthStateChanged(auth, async (userData) => {
      if (userData) {
        try {
          setUser(() => userData as User)

          const token = await getCustomToken()
          if (token) {
            onAuthorized(token, window.location.href)
          }
        } catch (e) {
          // eslint-disable-next-line
          console.log(e)
        }
      } else {
        try {
          onUnAuthorized()
          dispatch(commonActions.resetState())
        } catch (e) {
          // eslint-disable-next-line
          console.log(e)
        }

        setUser(null)
      }

      setFirstCheck(() => false)
    })
    // eslint-disable-next-line
  }, [])

  return { user, loaded: !firstCheck } as const
}
