import { useDialog } from 'boards-web-ui'

import EditVideoStreaming from '../components/EditVideoStreaming'
import { Node } from '../../../models'
import { IUseEditNodeVideoStreaming } from '../models/IUseEditNode'

const useEditVideoStreaming: IUseEditNodeVideoStreaming = (
  save,
  isPageEditor,
) => {
  const { open } = useDialog()

  const editVideoStreaming = (
    node: Node,
    folderId: string,
    boardId: string,
  ) => {
    open(
      <EditVideoStreaming
        boardId={boardId}
        folderId={folderId}
        node={node}
        isPageEditor={isPageEditor}
        onSave={(n) => save(node.id, n)}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  return editVideoStreaming
}

export default useEditVideoStreaming
