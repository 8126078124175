import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DaysLeftIndicatorData } from '@features/reverseTrial/models/reverseTrialModels'

import styles from './ReverseTrialReminder.module.css'

type Props = {
  getDaysLeftIndicatorData: () => DaysLeftIndicatorData
}

const ReverseTrialReminder: FC<Props> = ({ getDaysLeftIndicatorData }) => {
  const { t } = useTranslation()
  const [showDaysLeft, setShowDaysLeft] = useState(false)
  const [days, setDays] = useState(0)

  useEffect(() => {
    const checkIndicator = () => {
      const { daysLeft, shouldShowDays } = getDaysLeftIndicatorData()
      setShowDaysLeft(shouldShowDays)
      setDays(daysLeft)
    }
    checkIndicator()
    const intervalId = setInterval(checkIndicator, 10000)
    return () => clearInterval(intervalId)
  }, [getDaysLeftIndicatorData])

  return (
    <div className={styles.Root}>
      <span className={styles.Title}>{t('trial_indication_title')}</span>

      {showDaysLeft && (
        <>
          <span className={styles.Dot}>•</span>
          <span className={styles.Description}>
            {days < 2
              ? t('trial_indication_timer_singular')
              : `${days}  ${t('trial_indication_timer')}`}
          </span>
        </>
      )}
    </div>
  )
}

export default ReverseTrialReminder
