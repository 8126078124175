import { DocumentSnapshot, QuerySnapshot } from 'firebase/firestore'
import { folderActions } from '../../../actions'
import { AppDispatch } from '../../../app/store'
import { Folder } from '../../../app/models'
import { transformFolder } from '../transformers/FolderTransformer'

interface IItem {
  [id: string]: Folder
}

export const FoldersSnapshot =
  (uid: string, bid: string, dispatch: AppDispatch) =>
  async (snapshot: QuerySnapshot) => {
    const boardId = `${uid}-${bid}`
    const items = {} as IItem

    await Promise.all(
      snapshot.docChanges().map(async ({ doc, type }) => {
        if (type === 'added' || type === 'modified') {
          const data = await transformFolder(boardId, doc)
          items[doc.id] = data
        }

        if (type === 'removed') {
          dispatch(folderActions.deleteFolder(boardId, doc.id))
        }
      }),
    )

    if (Object.keys(items).length > 0) {
      dispatch(folderActions.setFolders(boardId, items))
    }
  }

export const FolderDoc = async (
  doc: DocumentSnapshot,
  boardId: string,
  dispatch: AppDispatch,
) => {
  const folder = await transformFolder(boardId, doc)

  dispatch(folderActions.setFolders(boardId, { [doc.id]: folder }))
}
