import { useContext, useMemo } from 'react'
import { UserCredential } from '@firebase/auth/dist/node-esm'

import {
  doSignOut,
  resetPasswordWithEmail,
  signInWithEmailAndPassword,
  signUpWithEmailAndPassword,
} from '../auth'
import {
  EmailPasswordSignIn,
  FireBaseAuthState,
  ResetPasswordData,
  SignUpData,
} from '../models/auth'

import FirebaseAuthContext from '../providers/FirebaseAuthContext'

export const useFirebaseAuth = (): FireBaseAuthState => {
  const { user, isLoggedIn, loaded } = useContext(FirebaseAuthContext)

  const signIn = (
    data: EmailPasswordSignIn,
  ): Promise<void | UserCredential> => {
    return signInWithEmailAndPassword(data)
  }

  const signUp = (data: SignUpData) => {
    return signUpWithEmailAndPassword(data)
  }

  const resetPassword = (data: ResetPasswordData) => {
    return resetPasswordWithEmail(data)
  }

  const signOut = () => {
    return doSignOut()
  }

  return useMemo(
    () => ({
      user,
      isLoggedIn,
      loaded,

      signIn,
      signUp,
      signOut,
      resetPassword,
    }),
    [user, isLoggedIn, loaded],
  )
}
