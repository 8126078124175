import React, { FC } from 'react'

import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import CampaignBanner from './CampaignBanner'
import { CampaignBanners as TCampaignBanners } from '../../../../_firebase/models/campaigns'

interface Props {
  banners: TCampaignBanners
  onClose: (id: string) => void
}

const CampaignBanners: FC<Props> = ({ banners, onClose }) => {
  const { openPaywall } = usePaywall()

  return (
    <>
      {Object.entries(banners).map(([id, banner]) => (
        <CampaignBanner
          key={id}
          banner={banner}
          onPaywall={() => openPaywall(PaywallSourceEnum.CAMPAIGN_BANNERS)}
          onClose={() => onClose(id)}
        />
      ))}
    </>
  )
}

export default React.memo(CampaignBanners)
