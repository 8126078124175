import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { extractBoardId } from '@helpers/extractBoardId'
import { boardIndicator, rootFolderIndicator } from '@helpers/NodeIdGenerator'
import { useSelectorFactory } from '@hooks/useSelectorFactory'

import useBoards from '../../../hooks/useBoards'
import { appLoading } from '../../../selectors'

const useRotesHelper = () => {
  const { boardId } = useParams()
  const { order, indexOfPagesBoard } = useBoards()
  const pending = useSelectorFactory(appLoading)

  const selectedBoardIndex = indexOfPagesBoard === -1 ? 0 : 1

  const nonBoardSelectedState =
    !pending && (!boardId || !order.includes(boardId)) && order?.length > 0

  const firstFolderId = order[selectedBoardIndex]
    ? extractBoardId(order[selectedBoardIndex]).replace(
        boardIndicator,
        rootFolderIndicator,
      )
    : null
  const firstBoardUrl =
    order && order[selectedBoardIndex]
      ? `/${order[selectedBoardIndex]}/${firstFolderId}`
      : null

  return useMemo(
    () => ({
      pending,
      nonBoardSelectedState,
      firstBoardUrl,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pending, nonBoardSelectedState, firstBoardUrl, order],
  )
}

export default useRotesHelper
