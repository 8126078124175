import { useEffect } from 'react'
import useBoards from '@hooks/useBoards'
import useBoardsDialogs from '@features/boardsMenu/hooks/useBoardsDialogs'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { isNumber } from 'lodash-es'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import useSubscriptionPlans from '@features/payments/hooks/useSubscriptionPlans'

const useInitBoardsLimitDialogs = () => {
  const { boards } = useBoards()
  const { status: plansStatus } = useSubscriptionPlans()
  const { openPaywall } = usePaywall()

  const {
    profile: { plan, uiState },
  } = useProfileInformation()
  const {
    openBoardsLimitationsAnnouncement,
    hideBoardsLimitationsAnnouncement,
  } = useBoardsDialogs()

  const boardLimit = plan?.limits?.boards
  const boardsLimitationsAnnouncementShown =
    uiState?.boardsLimitationsAnnouncementShown

  useEffect(() => {
    if (plansStatus !== 'loaded') {
      return
    }

    if (
      boardLimit === null ||
      boardLimit === 'unlimited' ||
      typeof boardLimit === 'undefined'
    ) {
      return
    }

    if (
      typeof boardsLimitationsAnnouncementShown === 'boolean' &&
      boardsLimitationsAnnouncementShown
    ) {
      return
    }

    hideBoardsLimitationsAnnouncement()

    if (isNumber(boardLimit) && Object.keys(boards).length > boardLimit) {
      openBoardsLimitationsAnnouncement(() =>
        openPaywall(PaywallSourceEnum.FREE_PLAN_UPDATE),
      )
    }

    // eslint-disable-next-line
  }, [plansStatus])
}

export default useInitBoardsLimitDialogs
