import { Trans } from 'react-i18next'

const longTextLength = 80

export const usePaywallTitle = ({
  currentPlanTitle,
  showNewPaywallOptions,
  shouldShowFreePlanIndicator,
  shouldShowFreeTrialIndicator,
}: {
  currentPlanTitle?: string
  showNewPaywallOptions: boolean
  shouldShowFreePlanIndicator?: boolean
  shouldShowFreeTrialIndicator?: boolean
}) => {
  const getTitle = () => {
    if (currentPlanTitle) {
      return currentPlanTitle
    }

    if (shouldShowFreeTrialIndicator) {
      return <Trans i18nKey={'paywall_before_trial_ended_title'} />
    }

    if (shouldShowFreePlanIndicator) {
      return <Trans i18nKey={'paywall_trial_ended_title'} />
    }

    if (showNewPaywallOptions) {
      return <Trans i18nKey={'paywall_title_two_tiers'} />
    }

    return <Trans i18nKey={'plans_page_title'} />
  }

  return {
    isLongText: currentPlanTitle
      ? currentPlanTitle.length > longTextLength
      : false,
    title: getTitle(),
  }
}
