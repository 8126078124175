import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const usePaywallPlansBulletsProps = () => {
  const { t } = useTranslation()

  const freePlanBullets = useMemo(() => {
    return {
      title: t('free_plan_features_title'),
      items: [
        t('free_plan_feature_boards'),
        t('free_plan_feature_keyboard'),
        t('free_plan_feature_content'),
        t('free_plan_feature_colab'),
        t('free_plan_feature_pages'),
      ],
    }
  }, [t])

  const businessPlanBullets = useMemo(() => {
    return {
      title: t('business_plan_features_title'),
      items: [
        t('business_plan_feature_unlimited_boards'),
        t('business_plan_feature_unlimited_keyboard'),
        t('business_plan_feature_video_voice'),
        t('business_plan_feature_messages'),
        t('business_plan_feature_restications'),
        t('business_plan_feature_analytics'),
      ],
    }
  }, [t])

  const businessReverseTrialBullets = useMemo(() => {
    return {
      title: null,
      items: [
        t('paywall_feature_boards'),
        t('paywall_feature_keyboard'),
        t('free_plan_pages'),
        t('paywall_feature_forms_reponses'),
        t('paywall_feature_collab'),
        t('paywall_feature_content_restrictions'),
        t('paywall_feature_content_analytics'),
      ],
    }
  }, [t])

  return { freePlanBullets, businessPlanBullets, businessReverseTrialBullets }
}
