import React, { FC, HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import {
  ButtonNew,
  Card,
  CardActions,
  CardBody,
  CardBodyContent,
  CardHeader,
  Icon,
  InfoWithBackgroundIcon,
  theme,
} from 'boards-web-ui'

import styles from './OnlyOwnerActionWarning.module.css'

type Props = HTMLAttributes<HTMLDivElement> & {
  onSubmit: () => void
}
const OnlyOwnerActionWarning: FC<Props> = ({
  onSubmit,
  className,
  ...rest
}) => {
  const { t } = useTranslation()

  return (
    <Card className={clsx(className, styles.Root)} {...rest}>
      <CardHeader
        bg={theme.theme.colors.colorBlue100}
        icon={
          <Icon size="80px" className={styles.HeaderIcon}>
            <InfoWithBackgroundIcon />
          </Icon>
        }
      />
      <CardBody>
        <CardBodyContent className={styles.Centred}>
          {t('forms_action_limited_to_boards_owner')}
        </CardBodyContent>
        <CardActions>
          <ButtonNew fullWidth color="primary" size="large" onClick={onSubmit}>
            {t('action_got_it')}
          </ButtonNew>
        </CardActions>
      </CardBody>
    </Card>
  )
}

export default OnlyOwnerActionWarning
