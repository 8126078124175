import { useCallback, useMemo } from 'react'

import useBoard from '@hooks/useBoard'
import {
  InformationIcon,
  PremiumIndicationIcon,
  useDialog,
} from 'boards-web-ui'
import { CoEditorDialog } from '@features/nodeEditor/components/CoEditorDialog'
import styles from '@features/nodeEditor/components/CoEditorDialog.module.css'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import usePaywall from '@hooks/usePaywall'
import { useFormsCheckoutPaidPlansEvents } from '@features/analytics/useFormsCheckoutPaidPlansEvents'

import { IconWrapper } from '@ui/components/IconWrapper'
import { Folder, NodeType } from '../../../models'

export type TPremiumLimitationNodeType = NodeType.FORM | NodeType.CHECKOUT

export const usePagePremiumLimitationMode = () => {
  const { board, isOwnBoard } = useBoard()

  const isLimitedNode = useMemo(
    () => (nodeType: NodeType) => {
      return (
        (nodeType === NodeType.FORM && !board?.options.formsAllowed) ||
        (nodeType === NodeType.CHECKOUT && !board?.options.checkoutAllowed)
      )
    },
    [board?.options.checkoutAllowed, board?.options.formsAllowed],
  )

  const getPremiumPreviewPageFolder = useMemo(
    () => (folder: Folder) => {
      const premiumContentNodeTypes = folder?.content
        ?.filter(({ type }) => isLimitedNode(type))
        ?.map(({ type }) => type)

      const isPremiumPreviewPageLimitationMode = Boolean(
        premiumContentNodeTypes?.length,
      )

      const previewPageFolder = isPremiumPreviewPageLimitationMode
        ? {
            ...folder,
            content: folder?.content?.filter(
              ({ type }) => !premiumContentNodeTypes.includes(type),
            ),
          }
        : folder

      const showPremiumPreviewPageLimitationModeBanner =
        isPremiumPreviewPageLimitationMode && isOwnBoard

      return {
        isPremiumPreviewPageLimitationMode,
        previewPageFolder,
        showPremiumPreviewPageLimitationModeBanner,
      }
    },
    [isOwnBoard, isLimitedNode],
  )

  const getLimitationKeyIcon = (nodeType: TPremiumLimitationNodeType) => {
    if (isLimitedNode(nodeType)) {
      return isOwnBoard ? (
        <IconWrapper>
          <PremiumIndicationIcon />
        </IconWrapper>
      ) : (
        <InformationIcon color={'#5DABFF'} />
      )
    }

    return null
  }

  const { open, close } = useDialog()
  const { openPaywall } = usePaywall()
  const { appPageCoEditorsNoAccessPopupView } =
    useFormsCheckoutPaidPlansEvents()

  const onLimitedKeyClick = useCallback(
    (
      source:
        | PaywallSourceEnum.FORM_CREATE
        | PaywallSourceEnum.CHECKOUT_CREATE
        | PaywallSourceEnum.FORM_EDIT
        | PaywallSourceEnum.CHECKOUT_EDIT,
      callback?: () => unknown,
    ) => {
      if (!isOwnBoard) {
        appPageCoEditorsNoAccessPopupView(source)

        return open(<CoEditorDialog onClose={close} />, {
          closeOnOverlayClick: true,
          className: styles.DialogRoot,
        })
      }

      if (callback) {
        return callback()
      }

      return openPaywall(source)
    },
    [appPageCoEditorsNoAccessPopupView, isOwnBoard, open, openPaywall, close],
  )

  return {
    getPremiumPreviewPageFolder,
    getLimitationKeyIcon,
    onLimitedKeyClick,
    isLimitedNode,
  } as const
}
