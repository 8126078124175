import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDialog } from 'boards-web-ui'
import { isNumber } from 'lodash-es'

import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'

import useUpload from '../../toolbar/hooks/useUpload'
import useUserFeatures, { Features } from '../../../../hooks/useUserFeatures'
import useUploadFileMetadata from '../../../../hooks/useUploadFileMetadata'
import useIsApiAvailableOrNotify from '../../../../hooks/useIsApiAvailableOrNotify'
import useAddVideoStreamingDialogs from './useAddVideoStreamingDialogs'

import { useProfileInformation } from '../../profile/hooks/useProfileInformation'
import { IUseAddVideoStreaming } from '../models/IUseAddNode'
import AddVideoStreaming from '../components/AddVideoStreaming'
import { AppState } from '../../../models/appState'

const MAX_UPLOAD_FILE_SIZE = 512000 // 512 MB
const MAX_UPLOAD_FILE_DURATION = 600 // seconds

const useAddVideoStreaming: IUseAddVideoStreaming = (
  save,
  update,
  isPageEditor,
) => {
  const { open } = useDialog()
  const isApiAvailableOrNotify = useIsApiAvailableOrNotify()
  const { getFileSize, getVideoMetadata } = useUploadFileMetadata()
  const {
    profile: { usage, plan },
  } = useProfileInformation()
  const { tryFree, enabled, limitReached } = useUserFeatures(
    Features.uploadVideo,
  )
  const { openPaywall } = usePaywall()
  const { hideVideoHint } = useProfileInformation()

  const videoUrl = useSelector(
    (state: AppState) => state.app.nodeEditor.nodeVideo.videoUrl,
  )
  const videoId = useSelector(
    (state: AppState) => state.app.nodeEditor.nodeVideo.videoId,
  )
  const videoUrlRef = useRef(videoUrl)
  const videoIdRef = useRef(videoId)

  useEffect(() => {
    videoUrlRef.current = videoUrl
    videoIdRef.current = videoId
  }, [videoUrl, videoId])

  const { maxFileSizeDialog, maxDurationDialog, limitReachedDialog } =
    useAddVideoStreamingDialogs()

  const uploadFile = async (file: File) => {
    if (!isApiAvailableOrNotify()) return

    if (getFileSize(file) >= MAX_UPLOAD_FILE_SIZE) {
      maxFileSizeDialog()
      return
    }

    const videoMetadata = await getVideoMetadata(file)
    if (
      Number.isFinite(videoMetadata.duration) &&
      isNumber(videoMetadata.duration) &&
      videoMetadata.duration >= MAX_UPLOAD_FILE_DURATION
    ) {
      maxDurationDialog()
      return
    }

    hideVideoHint()

    open(
      <AddVideoStreaming
        file={file}
        videoMetadata={videoMetadata}
        isTryFree={tryFree}
        isPageEditor={isPageEditor}
        onSave={save}
        onUpdate={update}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }

  const openFilePicker = useUpload(uploadFile, {
    accept: '.mov, .mp4',
    multiple: false,
  })

  const addVideoStreaming = () => {
    if (!enabled || (tryFree && limitReached)) {
      openPaywall(PaywallSourceEnum.VIDEO_LIMIT_REACHED)
      return
    }

    if (limitReached) {
      limitReachedDialog(usage.videos, plan.limits.videos)
      return
    }

    openFilePicker()
  }

  return addVideoStreaming
}

export default useAddVideoStreaming
