import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { ForwardIcon, Icon } from 'boards-web-ui'
import usePaywall from '@hooks/usePaywall'
import useBoards from '@hooks/useBoards'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import styles from './ReverseTrialPlanIndicator.module.css'

const ReverseTrialPlanIndicator = () => {
  const { t } = useTranslation()
  const { openPaywall } = usePaywall()

  const {
    profile: { plan },
  } = useProfileInformation()

  const { boards } = useBoards()
  const hasZeroActiveSharedBoard = Object.keys(boards)
    .filter((key) => {
      return !boards[key].isOwn
    })
    .map((key) => {
      return boards[key]
    })
    .every((item) => item.isActive === false)

  const numberBoardsLimit = plan?.limits?.boards
  const shouldShowDescription =
    numberBoardsLimit > 0 && hasZeroActiveSharedBoard

  const handleUpgrade = () => {
    openPaywall(PaywallSourceEnum.FREE_PLAN_INDICATION)
  }

  return (
    <div
      className={clsx(
        styles.Root,
        shouldShowDescription && styles.withDescription,
      )}
    >
      <span
        className={clsx(
          styles.Title,
          !shouldShowDescription && styles.TitleWithPadding,
        )}
      >
        {t('free_plan_indication_title')}
      </span>
      {shouldShowDescription && (
        <span className={styles.Description}>
          {t('shared_board_limit_text', {
            number: numberBoardsLimit,
          })}
        </span>
      )}
      <button
        className={clsx(
          styles.ActionButton,
          shouldShowDescription && styles.ActionButtonWithMargin,
        )}
        onClick={handleUpgrade}
      >
        {t('paywall_upgrade')}
        <Icon>
          <ForwardIcon />
        </Icon>
      </button>
    </div>
  )
}

export default ReverseTrialPlanIndicator
