import React from 'react'

import { Avatar } from '../../../../ui/components/Avatar'

import { useProfileName } from '../hooks/useProfileName'

import styles from './ProfileAvatar.module.css'
import useConnectionState from '../../../../_firebase/hooks/useConnectionState'

type Props = {
  className?: string
}
export const ProfileAvatar: React.FC<Props> = ({ className, ...rest }) => {
  const { isConnected } = useConnectionState()
  const { name: displayName, loaded } = useProfileName()

  if (!loaded) {
    return null
  }

  return (
    <div className={[styles.Root, className].join(' ')} {...rest}>
      <Avatar size={34} className={styles.Avatar}>
        {displayName.trim().charAt(0)}
      </Avatar>
      <span className={styles.Name}>
        {displayName}&nbsp;
        {!isConnected && '(offline)'}
      </span>
    </div>
  )
}
