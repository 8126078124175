import { PLANS } from 'app/constants/routes'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import usePaywallEvents from '@features/analytics/usePaywallEvents'

const usePaywall = () => {
  const { paywallSource } = usePaywallEvents()

  const openPaywall = (source: PaywallSourceEnum) => {
    paywallSource(source)
    window.open(`${PLANS}?from-source=${source}`, '_blank')
  }

  return {
    openPaywall,
  } as const
}

export default usePaywall
