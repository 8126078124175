/* eslint-disable no-underscore-dangle */
import { colors } from '../config'

interface IChart {
  tooltip: { _active: { element: { x: number; y: number } }[] }
  ctx: CanvasRenderingContext2D
  chartArea: { top: number; bottom: number }
}

const annotationLinePlugin = {
  id: 'annotationLine',
  afterDraw: (chart: IChart) => {
    if (chart.tooltip?._active && chart.tooltip._active.length) {
      const ctx = chart.ctx as CanvasRenderingContext2D

      ctx.save()

      ctx.strokeStyle = colors.blue700

      // Dashed line
      ctx.beginPath()
      ctx.lineWidth = 1
      ctx.setLineDash([4, 6])
      ctx.moveTo(chart.tooltip._active[0].element.x, chart.chartArea.top)
      ctx.lineTo(chart.tooltip._active[0].element.x, chart.chartArea.bottom)
      ctx.stroke()

      // Dot
      ctx.setLineDash([])
      ctx.lineWidth = 3
      ctx.fillStyle = 'white'
      ctx.beginPath()
      ctx.arc(
        chart.tooltip._active[0].element.x,
        chart.tooltip._active[0].element.y,
        8,
        0,
        2 * Math.PI,
      )
      ctx.closePath()
      ctx.fill()
      ctx.stroke()

      ctx.restore()
    }
  },
}

export default annotationLinePlugin
