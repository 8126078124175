import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useRotesHelper from './useRotesHelper'

const useAutoSelectFirstOrderBoard = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { nonBoardSelectedState, firstBoardUrl } = useRotesHelper()

  useEffect(() => {
    if (nonBoardSelectedState && firstBoardUrl) {
      navigate(`${firstBoardUrl}?${searchParams.toString()}`)
    }

    // eslint-disable-next-line
  }, [nonBoardSelectedState])

  return !nonBoardSelectedState
}

export default useAutoSelectFirstOrderBoard
