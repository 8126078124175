import { Tick, TooltipXAlignment, TooltipYAlignment } from 'chart.js'
import { colors } from '../config'
import { longDateFormat, shortDateFormat } from '../utils'
import customTooltip from './customTooltip'

const createOptions = (
  tooltipLabel: string,
  labels: Date[],
  values: (number | undefined)[],
) => {
  const moreThanOneYear = labels.length > 365
  const moreThanTwoYear = labels.length > 365 * 2
  const valuesWithoutUndefined = values
    .filter((v) => v !== undefined)
    .map((v) => Number(v))
  const maxValue = Math.max(...valuesWithoutUndefined)

  const setTickets = (_: unknown, index: number, ticks: Tick[]) => {
    const date = labels[index]
    if (date) {
      const day = date.getDate()
      const month = date.getMonth()

      if (ticks.length < 30 * 9) {
        return shortDateFormat(date)
      }

      if (day === 1 && (!moreThanTwoYear || month % 2)) {
        return longDateFormat(date)
      }
    }

    return undefined
  }

  return {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        intersect: false,
        xAlign: 'center' as TooltipXAlignment,
        yAlign: 'top' as TooltipYAlignment,
        usePointStyle: false,
        xPadding: 100,
        external: customTooltip(tooltipLabel),
        padding: 9,
      },
      myEventCatcher: {},
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: moreThanOneYear ? 24 : 16,
          color: colors.gray600,
          callback: setTickets,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        offset: true,
      },
      y: {
        max: maxValue < 5 ? 5 : undefined,
        ticks: {
          color: colors.gray600,
          maxTicksLimit: maxValue < 10 ? 6 : 11,
        },
        beginAtZero: true,
        grid: {
          color: colors.blue100,
          drawBorder: false,
          tickColor: 'white',
          tickLength: 20,
        },
      },
    },
  }
}

export default createOptions
