import { memo } from 'react'
import { FixedList } from 'react-recycled-list'
import { MemberOption } from '../models/MemberOption'
import Member from './Member'

import styles from './MembersRecycledList.module.css'

interface Props {
  data: MemberOption[]
}

interface RowProps {
  data: MemberOption[]
  dataIndex: number
  dataEndIndex: number
  top: number
  height: number
}
const MembersRecycledList = ({ data }: Props) => {
  return (
    <FixedList
      listProps={{ className: styles.Root }}
      height={350}
      rowComponent={Row}
      data={data}
      rowHeight={56}
      column={2}
    />
  )
}

const Row = memo((props: RowProps) => {
  const { data, dataIndex: dataStartIndex, dataEndIndex, top, height } = props

  const rowData = data.slice(dataStartIndex, dataEndIndex)

  return (
    <div className={styles.Row} style={{ top, height }}>
      {rowData.map(
        ({
          role,
          email,
          permission,
          selected,
          onClick,
          onChangePermission,
        }) => (
          <Member
            className={styles.MemberItem}
            role={role}
            key={email}
            email={email}
            permission={permission}
            selected={selected}
            onClick={onClick}
            onChangePermission={onChangePermission}
          />
        ),
      )}
    </div>
  )
})

export default MembersRecycledList
