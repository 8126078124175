import { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import TextAreaResizable from '../../../../elements/TextAreaResizable'

import styles from './EditLink.module.css'

const nearrestMultipleOfN = (number, n) => {
  const value = number < n ? n : number
  return n * ((value / n + 0.5) | 0)
}

const EditLinkTextArea = ({ value, step, error, inputRef, onChange }) => {
  let timeout
  const { t } = useTranslation()
  const editBtnRef = useRef()
  const [active, setActive] = useState(false)
  const [editing, setEditing] = useState(false)
  const [tooltipX, setTooltipX] = useState(false)
  const [tooltipY, setTooltipY] = useState(false)

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, 400)
  }

  const hideTip = () => {
    timeout = setTimeout(() => {
      clearInterval(timeout)
    }, 400)
  }

  const handleOnMouseEnter = (e) => {
    if (!active) {
      const rect = e.target.getBoundingClientRect()
      const x = parseInt(e.clientX - rect.left, 10)
      const y = parseInt(e.clientY - rect.top, 10)

      setTooltipX(x)
      setTooltipY(nearrestMultipleOfN(y, 17) + 3)
      setActive(true)
    }
  }

  const handleOnMouseLeave = (event) => {
    const targetElement = event.toElement || event.relatedTarget
    try {
      if (!targetElement.isEqualNode(editBtnRef.current)) {
        setActive(false)
      }
      // eslint-disable-next-line
    } catch (err) {}
  }

  const handleOnClickEdit = () => {
    setEditing(true)
    inputRef.current.focus()
  }

  const handleOnClick = () => {
    if (!editing && step === 2) {
      window.open(value, '_blank')
    }
  }

  const TextareaClass =
    step === 2 && !editing ? styles.Textarea : styles.TextareaEditing

  return (
    <div
      className={styles.TooltipWrapper}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      onClick={(e) => e.stopPropagation}
    >
      {step === 1 && (
        <label className={styles.Label}>
          <Trans i18nKey="content_key_link" />
        </label>
      )}
      {false && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
          className={styles.TextareaLink}
          href={value}
        >
          {value}
        </a>
      )}
      <TextAreaResizable
        inputRef={inputRef}
        className={TextareaClass}
        placeholder={t('link_paste_your_link_here')}
        rows={1}
        onChange={(e) => onChange(e.target.value)}
        onClick={handleOnClick}
        onBlur={() => setEditing(false)}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        value={value}
        size={15}
      />
      {false && <div className={styles.Underline}></div>}
      {!editing && active && step === 2 && (
        <button
          ref={editBtnRef}
          onClick={handleOnClickEdit}
          className={styles.TooltipTip}
          aria-label="Edit link"
          style={{ top: tooltipY, left: tooltipX }}
        >
          <Trans i18nKey="node_link_edit_link" />
        </button>
      )}
      {error && <p className={styles.ErrorMessage}>{error}</p>}
    </div>
  )
}

export default EditLinkTextArea
