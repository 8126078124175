import { FC, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { Trans } from 'react-i18next'
import { ButtonNew, PremiumCrownIcon } from 'boards-web-ui'
import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import { useFormsCheckoutPaidPlansEvents } from '@features/analytics/useFormsCheckoutPaidPlansEvents'
import { IconWrapper } from '@ui/components/IconWrapper'

import styles from './PremiumBanner.module.css'

interface Props {
  isPagePreview?: boolean
  isEmpty?: boolean
  text: string
}

export const PremiumBanner: FC<Props> = ({ isPagePreview, isEmpty, text }) => {
  const { openPaywall } = usePaywall()
  const { appPageUnsubscribedBannerView, appPageUnsubscribedBannerClick } =
    useFormsCheckoutPaidPlansEvents()

  const eventSource = isPagePreview
    ? PaywallSourceEnum.PAGE_PREVIEW
    : PaywallSourceEnum.PAGE_EDITOR

  const handleBannerClick = useCallback(() => {
    appPageUnsubscribedBannerClick(eventSource)
    openPaywall(eventSource)
  }, [appPageUnsubscribedBannerClick, eventSource, openPaywall])

  useEffect(() => {
    appPageUnsubscribedBannerView(eventSource)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={clsx(
        styles.Root,
        isPagePreview && styles.PagePreview,
        isEmpty && styles.isEmpty,
      )}
    >
      <span className={styles.UpgradeText}>
        <Trans i18nKey={text} />
      </span>

      <ButtonNew
        color={'primary'}
        size={'medium'}
        startIcon={
          <IconWrapper size={16} color={'white'} className={styles.KingIcon}>
            <PremiumCrownIcon />
          </IconWrapper>
        }
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()

          handleBannerClick()
        }}
      >
        <Trans i18nKey={'action_upgrade'} />
      </ButtonNew>
    </div>
  )
}
