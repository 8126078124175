import { useCallback } from 'react'
import paths from '@features/board/helpers/url'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import { Folder, Node } from '../app/models'
import { SHARED_FOLDERS } from '../constants/routes'

const useShortcut = () => {
  const { profile } = useProfileInformation()
  const getShortcut = useCallback(
    (node: Node | Folder | string) => {
      if (!profile.shortcut) {
        return null
      }
      if (typeof node === 'string') {
        return `${paths.shortcutDomain}/${profile.shortcut}.${node}`
      }
      if (node?.shortcut) {
        return `${paths.shortcutDomain}${SHARED_FOLDERS.oldRoot}/${profile.shortcut}.${node.shortcut}`
      }

      return null
    },
    [profile.shortcut],
  )

  return { getShortcut }
}

export default useShortcut
