import { useCallback } from 'react'
import useBoard from '../../../hooks/useBoard'
import { useLogEvents } from './useLogEvents'
import { EventCategories } from './models/logEvents'
import { Node, NodeType } from '../../models'
import useShortcut from '../../../hooks/useShortcut'
import useFolders from '../../../hooks/useFolders'

enum TargetNames {
  DOWNLOAD = 'download',
  COPY_TO_CLIPBOARD = 'copy_to_clipboard',
}

// prettier-ignore
export enum ScreenNames {
  CONTENT_EDIT      = 'content_edit',     // Edit dialog
  CONTENT_PREVIEW   = 'content_preview',  // Preview screen for standalone nodes
  PAGE_PREVIEW      = 'page_preview',     // Preview screen for pages
  PAGE_PREVIEW_TAB  = 'page_preview_tab', // Mobile viewer in page builder
}

export const AnalyticsNodeType = {
  [NodeType.FOLDER]: NodeType.FOLDER,
  [NodeType.VIDEO]: NodeType.VIDEO,
  [NodeType.VIDEO_STREAMING]: 'video_hosted',
  [NodeType.AUDIO]: 'voice',
  [NodeType.IMAGE]: 'photo',
  [NodeType.TEXT]: NodeType.TEXT,
  [NodeType.LINK]: NodeType.LINK,
  [NodeType.PDF]: NodeType.PDF,
  [NodeType.PAGE]: NodeType.PAGE,
  [NodeType.PARAGRAPH]: NodeType.PARAGRAPH,
  [NodeType.HEADLINE]: NodeType.HEADLINE,
  [NodeType.BUTTON]: NodeType.BUTTON,
  [NodeType.FORM]: NodeType.FORM,
  [NodeType.CHECKOUT]: NodeType.CHECKOUT,
  [NodeType.PROFILE]: NodeType.PROFILE,
}

const useNodeEvents = () => {
  const { boardId, isOwnBoard } = useBoard()
  const { buttonClick, trigger } = useLogEvents()
  const { getShortcut } = useShortcut()
  const { folders } = useFolders()

  const getNodeShortcut = useCallback(
    (node?: Node) => {
      if (!node) {
        return ''
      }

      const folderShortcut = folders ? getShortcut(folders[node.id]) : ''

      if (folderShortcut) {
        return folderShortcut
      }

      if (node) {
        return getShortcut(node) || ''
      }

      return ''
    },
    // avoid triggering when folders are changed as only folder shortcut is needed
    // eslint-disable-next-line
    [getShortcut],
  )

  const downloadEvent = (node: Node) => {
    buttonClick(TargetNames.DOWNLOAD, {
      board_id: boardId,
      key_id: node.id,
      board_type: isOwnBoard ? 'own' : 'shared',
    })
  }

  const contentEditScreenEvent = (node: Node) =>
    trigger(EventCategories.APP_SCREEN_VIEW, {
      content_type: AnalyticsNodeType[node.type],
      screen: ScreenNames.CONTENT_EDIT,
      board_id: boardId,
      key_id: node.id,
      board_type: isOwnBoard ? 'own' : 'shared',
      link_shortcut: getNodeShortcut(node),
    })

  const discardChangesEvent = () =>
    trigger(EventCategories.APP_CHANGE_DISCARD, {
      board_type: isOwnBoard ? 'own' : 'shared',
    })

  const audioPlayEvent = (node: Node) =>
    trigger(EventCategories.APP_AUDIO_PLAY, {
      board_type: isOwnBoard ? 'own' : 'shared',
      key_id: node.id,
      screen: ScreenNames.CONTENT_PREVIEW,
    })

  const videoPlayEvent = (
    node: Node,
    screen: ScreenNames,
    params?: { [key: string]: unknown },
  ) =>
    trigger(EventCategories.APP_VIDEO_PLAY, {
      board_type: isOwnBoard ? 'own' : 'shared',
      key_id: node.id,
      screen,
      ...params,
    })

  return {
    audioPlayEvent,
    videoPlayEvent,
    downloadEvent,

    contentEditScreenEvent,
    discardChangesEvent,
  }
}

export default useNodeEvents
