import { addNodes, getNodes, nodesWithNewKeys } from '../FolderManagement'
import { ICopyNodesParams } from '../../_firebase/nodeActions'
import { AppState } from '../../app/models/appState'
import { initialState } from '../initialState'
import { clearProperties } from '../../_firebase/utils'

export function copyNodesReducer(
  state: AppState,
  {
    targetBoardId,
    targetFolderId,
    nodes: nodesToCopy,
    key,
    fromParams: { fromFolderId, fromBoardId, fromShortcutId },
  }: ICopyNodesParams,
  undoStackWithContentAdded: (state: AppState, boardId: string) => unknown,
) {
  const nodesToAdd = getNodes(
    fromShortcutId ? state.app.sharedFolder.folders : state.folders,
    fromBoardId,
    fromFolderId,
    nodesToCopy,
    fromShortcutId,
  )

  const { nodes, folders } = nodesWithNewKeys(
    state.folders[targetBoardId],
    nodesToAdd.map((node) => clearProperties(node)),
    key,
    state.folders[targetBoardId][targetFolderId]?.isPage,
  )

  const foldersWithNewNodes = addNodes(
    { ...state.folders, [targetBoardId]: folders },
    targetBoardId,
    targetFolderId,
    nodes,
  )

  return {
    ...state,
    folders: foldersWithNewNodes,
    app: {
      ...state.app,
      undoStack: undoStackWithContentAdded(state, targetBoardId),
      bulk: initialState.app.bulk,
    },
  }
}
