import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useDialog } from 'boards-web-ui'

import { useToast } from '@hooks/useToast'

import useCheckoutAnalytics from '@features/analytics/useCheckoutAnalytics'
import PayPalDisconnectWarning from '@features/checkout/components/PayPalDisconnectWarning'
import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'
import OnlyOwnerActionWarning from '@features/checkout/components/OnlyOwnerActionWarning'
import {
  disablePayPalAuthorization,
  FormIntegrationStatusData,
  startingPayPalOnboarding,
} from '_firebase'
import profileInfoActions from '../../../../actions/profile'
import styles from '../components/CheckoutProviderWarning.module.css'

type Props = {
  isOwn: boolean
  formIntegrationStatusState: FormIntegrationStatusData | null
  paymentProviders: Array<string>
  onGetFormIntegrationStatus: () => Promise<FormIntegrationStatusData | void>
}
const usePaymentProviders = ({
                               isOwn,
                               formIntegrationStatusState,
                               paymentProviders,
                               onGetFormIntegrationStatus,
                             }: Props) => {
  const { isPayPalAccountConnected } = useProfileInformation()
  const windowObjectReferenceRef = useRef<Window | null>(null);
  const dispatch = useDispatch()
  const { open, close } = useDialog()
  const toast = useToast()
  const {
    appCheckoutConnectPaypalClick,
    appCheckoutDisconnectPaypalEvent,
    appPaypalIntegrationSuccess,
  } = useCheckoutAnalytics()

  const [paymentProvidersState, setPaymentProvidersState] =
    useState<Array<string>>(paymentProviders)
  const [isPayPalConnectionPending, setIsPayPalConnectionPending] =
    useState<boolean>(false)
  const [isPayPalDisconnectedPending, setIsPayPalDisconnectedPending] =
    useState<boolean>(false)

  const isPaypalFormConnected = useMemo(() => {
    return (
      formIntegrationStatusState?.payPalAuthorization?.status ===
      'authorized' && paymentProvidersState.includes('paypal')
    )
  }, [
    paymentProvidersState,
    formIntegrationStatusState?.payPalAuthorization?.status,
  ])

  useEffect(() => {
    const checkStatus = async () => {
      await onGetFormIntegrationStatus()
      setPaymentProvidersState(['paypal'])
      setIsPayPalConnectionPending(false)
      appPaypalIntegrationSuccess()
      toast('PayPal connected successfully', true, 0, true)
    }

    if (isPayPalConnectionPending && isPayPalAccountConnected) {
      checkStatus()
    }
  }, [
    toast,
    isPayPalConnectionPending,
    appPaypalIntegrationSuccess,
    onGetFormIntegrationStatus,
    isPayPalAccountConnected,
  ])

  const onPaypalConnect = useCallback(() => {
    if (!isOwn) {
      open(<OnlyOwnerActionWarning onSubmit={close} />, {
        className: styles.Clear,
        withFullScreenDialogContainer: true,
      })
      return
    }

    appCheckoutConnectPaypalClick(
      formIntegrationStatusState?.payPalAuthorization?.status === 'authorized',
    )

    if (
      formIntegrationStatusState?.payPalAuthorization?.status ===
      'authorized' &&
      !paymentProvidersState.includes('paypal')
    ) {
      setPaymentProvidersState(['paypal'])
      toast('PayPal connected successfully', true, 0, true)
      return
    }

    if (!formIntegrationStatusState?.payPalAuthorization.authorizationURL) {
      return
    }

    setIsPayPalConnectionPending(true)
    startingPayPalOnboarding()


    if (windowObjectReferenceRef.current === null || windowObjectReferenceRef.current.closed) {
      windowObjectReferenceRef.current = window.open(
        formIntegrationStatusState?.payPalAuthorization.authorizationURL,
        'PayPalWindow',
        'width=600,height=800,scrollbars=yes,resizable=yes'
      )
    } else {
      windowObjectReferenceRef.current.focus();
    }

  }, [
    isOwn,
    formIntegrationStatusState?.payPalAuthorization?.status,
    paymentProvidersState,
    formIntegrationStatusState?.payPalAuthorization.authorizationURL,
    appCheckoutConnectPaypalClick,
    open,
    close,
    toast,
  ])

  const paypalDisconnect = useCallback(async () => {
    try {
      setIsPayPalDisconnectedPending(true)
      dispatch(profileInfoActions.setPayPalAuthorization('disabled'))

      await disablePayPalAuthorization()
      await onGetFormIntegrationStatus()
      appCheckoutDisconnectPaypalEvent()
      setIsPayPalDisconnectedPending(false)
    } catch {
      setIsPayPalDisconnectedPending(false)
    }
  }, [dispatch, onGetFormIntegrationStatus, appCheckoutDisconnectPaypalEvent])

  const onPaypalDisconnect = useCallback(() => {
    if (!isOwn) {
      open(<OnlyOwnerActionWarning onSubmit={close} />, {
        className: styles.Clear,
        withFullScreenDialogContainer: true,
      })
      return
    }

    open(
      <PayPalDisconnectWarning
        onSubmit={() => {
          paypalDisconnect()
          close()
        }}
        onCancel={close}
      />,
      {
        className: styles.Clear,
        withFullScreenDialogContainer: true,
      },
    )
  }, [open, close, paypalDisconnect, isOwn])

  return {
    isPayPalAccountConnected:
      formIntegrationStatusState?.payPalAuthorization?.status === 'authorized',
    paymentProviders: paymentProvidersState,
    isPaypalFormConnected,
    onPaypalConnect,
    onPaypalDisconnect,
    isPayPalConnectionPending,
    isPayPalDisconnectedPending,

    merchantPaymentsReceivable:
    formIntegrationStatusState?.payPalAuthorization
      ?.merchantPaymentsReceivable,
    merchantPrimaryEmailConfirmed:
    formIntegrationStatusState?.payPalAuthorization
      ?.merchantPrimaryEmailConfirmed,
  } as const
}
export default usePaymentProviders
