import { useParams } from 'react-router-dom'
import { TNodeCheckoutForm, useDialog } from 'boards-web-ui'

import useBoard from '@hooks/useBoard'
import usePaywall from '@hooks/usePaywall'
import { Checkout } from '@features/checkout'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'

import { useProfileInformation } from '@features/profile/hooks/useProfileInformation'

import { INodeProps } from '@features/nodeEditor/models/IUseNodeCreatorAPI'
import { CheckoutActionsIdsEnum } from '@features/checkout/models'

import { Node } from '../../../models'
import { IUseSaveNode } from '../models/IUseEditNode'

const useEditCheckout: IUseSaveNode = (onUpdate) => {
  const { open, close } = useDialog()
  const { openPaywall } = usePaywall()

  const {
    profile: {
      plan: {
        features: { advancedForms },
      },
    },
  } = useProfileInformation()

  const { boardId, isOwnBoard } = useBoard()
  const { pageId } = useParams()

  const onPaywall = () => {
    openPaywall(PaywallSourceEnum.FORM_POST_SUBMISSION)
  }

  const onSave = (id: string, updatedCheckoutNode: INodeProps) => {
    onUpdate(id, updatedCheckoutNode)
    close()
  }

  return (
    checkoutNode: Node,
    initialScreen = CheckoutActionsIdsEnum.PRODUCTS,
  ) => {
    open(
      <Checkout
        isOwn={isOwnBoard}
        boardId={boardId}
        pageId={pageId || ''}
        advancedForms={advancedForms}
        initialScreen={initialScreen as CheckoutActionsIdsEnum}
        initialNode={checkoutNode as unknown as TNodeCheckoutForm}
        onPaywall={onPaywall}
        onClose={close}
        onSave={(updatedCheckoutNode) =>
          onSave(checkoutNode.id, updatedCheckoutNode)
        }
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }
}

export default useEditCheckout
