/* eslint react/no-array-index-key: 0 */
import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDetectOutsideClick } from '../hooks/useDetectOutsideClick'
import EmojiCollection from './EmojiCollection'
import styles from './EmojiPicker.module.css'

const sideClasses = {
  left: styles.Left,
  right: styles.Right,
}

const EmojiPicker = ({
  side = 'left',
  onClickEmoji,
  onClickOutside,
  standalone = false,
}) => {
  const [selectedGroup, setSelectedGroup] = useState('food')
  const groups = Object.keys(EmojiCollection)
  const emojis = EmojiCollection[selectedGroup]
  const pickerRef = useRef(null)
  const [isActive] = useDetectOutsideClick(pickerRef, true)

  useEffect(() => {
    if (!isActive && onClickOutside) {
      onClickOutside()
    }
  }, [isActive, onClickOutside])

  const handleOnClick = (event, emoji) => {
    event.stopPropagation()
    onClickEmoji(emoji)
  }

  const bckClasses = [styles.Background, sideClasses[side]]

  if (standalone) {
    bckClasses.push(styles.standaloneBackground)
  }

  return (
    <div
      className={[styles.Root, standalone ? styles.standaloneRoot : ''].join(
        ' ',
      )}
      ref={pickerRef}
    >
      <div className={bckClasses.join(' ')}>
        <div className={styles.Groups}>
          {groups.map((group) => (
            <div
              key={group}
              className={
                group === selectedGroup ? styles.GroupSelected : styles.Group
              }
              onClick={() => setSelectedGroup(group)}
            >
              {group}
            </div>
          ))}
        </div>
        <div className={styles.PickerEmojis}>
          {emojis.map((emoji, i) => (
            <div
              key={i}
              className={styles.Emoji}
              onClick={(event) => handleOnClick(event, emoji)}
            >
              <span className={styles.Reference}>A</span>
              <span className={styles.Char}>{emoji}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

EmojiPicker.propTypes = {
  side: PropTypes.oneOf(['left', 'right']),
}

export default EmojiPicker
