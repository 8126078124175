import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import isEmail from '@stdlib/assert-is-email-address'
import { filterFirstError } from '@helpers/formErrors'
import GuestInputWrapper from './GuestInputWrapper'
import GuestTitle from './GuestTitle'
import Link from '../../../../elements/Link'
import Button from '../../../../elements/Button'
import InputCheckbox from '../../../../elements/InputCheckbox'
import InputField from '../../../../elements/InputField'
import * as ROUTES from '../../../../constants/routes'
import styles from './SignInForm.module.css'
import { useSignIn } from '../hooks/useSignIn'
import GuestFooter from './GuestFooter'
import Layout from '../../layout/Layout'

const SignInForm = () => {
  const { t } = useTranslation()

  const {
    setError,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: {
      remember: true,
    },
  })
  const { submitting, onSignIn } = useSignIn(setError)
  const [searchParams, setSearchParams] = useSearchParams()
  const referralCode = searchParams.get('ref')
  const redirect = searchParams.get('redirect')
  const shortcutId = searchParams.get('shortcutId')
  const boardId = searchParams.get('boardId')
  const folderId = searchParams.get('folderId')
  const linkShortcut = searchParams.get('link-shortcut')

  useEffect(() => {
    if (redirect) {
      const emailPattern = /[\w.-]+@[\w.-]+/
      const emailMatch = redirect.match(emailPattern)
      if (emailMatch && isEmail(emailMatch[0])) {
        const matchedEmail = emailMatch[0]
        setValue('email', matchedEmail)
        const newUrl = redirect.split('?')
        searchParams.set('redirect', newUrl[0])
        searchParams.delete('link-shortcut')
        searchParams.delete('source')
        setSearchParams(searchParams)
      }
    }
    // eslint-disable-next-line
  }, [redirect])

  const onSubmit = async (data) => {
    await onSignIn(data)
  }

  const firstError = filterFirstError(errors, ['email', 'password'])

  return (
    <Layout>
      <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
        <GuestInputWrapper>
          <GuestTitle>
            <Trans i18nKey="action_sign_in" />
          </GuestTitle>
        </GuestInputWrapper>
        <GuestInputWrapper>
          <InputField
            placeholder={t('authentication_screen_email_placholder')}
            {...register('email', {
              required: t('singup_error_no_email_filled'),
              pattern: {
                value: isEmail,
                message: t('errors.invalid_email_address'),
              },
            })}
            errors={firstError}
          />
        </GuestInputWrapper>
        <GuestInputWrapper>
          <InputField
            type="password"
            placeholder={t('authentication_screen_password')}
            {...register('password', {
              required: t('singup_error_no_password_filled'),
              minLength: {
                value: 6,
                message: t('singup_error_min_characters', {
                  field: 'Password',
                  min: 6,
                }),
              },
            })}
            errors={firstError}
          />
        </GuestInputWrapper>
        <GuestInputWrapper>
          <div className={styles.RememberMe}>
            <InputCheckbox type="checkbox" {...register('remember')}>
              <Trans i18nKey="authentication_screen_remember_me" />
            </InputCheckbox>
            <Link to={ROUTES.RESET_PASSWORD}>
              <Trans i18nKey="authentication_screen_forgot_password" />
            </Link>
          </div>
        </GuestInputWrapper>
        <Button loading={submitting} size={'lg'}>
          <Trans i18nKey="action_sign_in" />
        </Button>

        <GuestFooter
          link={`${ROUTES.SIGN_UP}${
            referralCode ? `?ref=${referralCode}` : ''
          }${
            shortcutId
              ? `?shortcutId=${shortcutId}&boardId=${boardId}&folderId=${folderId}&link-shortcut=${linkShortcut}`
              : ''
          }`}
          linkKey="action_sign_up"
          msgKey="authentication_screen_dont_have_an_account"
        />
      </form>
    </Layout>
  )
}
export default SignInForm
