import {
  FC,
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useState,
  HTMLAttributes,
} from 'react'

import clsx from 'clsx'

import VerticalScrollShadow from '../app/features/insights/components/VerticalScrollShadow'
import styles from './ModalEditWithConfirmation.module.css'

type Props = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    headerControls?: ReactNode
    footerControls?: ReactNode
    className?: string
    shadow?: boolean
    responsiveHeight?: boolean
  }
>
type WithTitleProps = Props & {
  title?: string
  withBorders?: boolean
  withFixedBody?: boolean
}
const ModalEditWithConfirmation: FC<Props> = ({
  headerControls,
  footerControls,
  className,
  children,
  shadow,
  responsiveHeight,
}) => {
  return (
    <div
      className={[
        styles.Root,
        responsiveHeight && styles.ResponsiveHeight,
        className,
      ].join(' ')}
    >
      {headerControls && <div className={styles.Header}>{headerControls}</div>}
      <div className={styles.ScrollerContent}>{children}</div>
      {footerControls && (
        <div className={clsx(styles.Footer, shadow && styles.Shadow)}>
          {footerControls}
        </div>
      )}
    </div>
  )
}

export const ModalEditWithConfirmationAndTitle = forwardRef<
  HTMLDivElement,
  WithTitleProps
>(
  (
    {
      headerControls,
      footerControls,
      className,
      children,
      shadow,
      title,
      responsiveHeight,
      withBorders,
      withFixedBody,
      ...rest
    },
    ref,
  ) => {
    const [stickyHead, setStickyHead] = useState(false)
    return (
      <div
        className={[
          styles.Root,
          responsiveHeight && styles.ResponsiveHeight,
          footerControls && styles.WithFooterControls,
          className,
        ].join(' ')}
        {...rest}
      >
        {headerControls && (
          <div className={clsx(styles.Header, styles.HeaderWithTitle)}>
            {title && (
              <div
                className={clsx(
                  styles.ModalTitle,
                  !stickyHead && styles.Visible,
                )}
              >
                {title}
              </div>
            )}
            {headerControls}
          </div>
        )}
        <div
          className={[
            styles.VerticalScrollWrapper,
            withBorders ? styles.WithBorders : '',
            withFixedBody ? styles.FixedHeight : '',
          ].join(' ')}
        >
          <VerticalScrollShadow
            ref={ref}
            className={styles.VerticalScroll}
            onTopCallBack={setStickyHead}
          >
            {title && (
              <div className={clsx(styles.Title, stickyHead && styles.Visible)}>
                {title}
              </div>
            )}
            {children}
          </VerticalScrollShadow>
        </div>
        {footerControls && (
          <div className={clsx(styles.Footer, shadow && styles.Shadow)}>
            {footerControls}
          </div>
        )}
      </div>
    )
  },
)
export default ModalEditWithConfirmation
