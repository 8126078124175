import UAParser from 'ua-parser-js'

const deviceParser = new UAParser()

export const isMobile = deviceParser.getDevice().type === 'mobile'

type SupportedLang = 'en' | 'es' | 'fr' | 'de' | 'pt' | 'it'
const supportedLanguages: Array<SupportedLang> = [
  'en',
  'es',
  'fr',
  'de',
  'pt',
  'it',
]

const getSupportedDeviceLanguage = (_navigator: Navigator): SupportedLang => {
  const userLang = _navigator?.language?.split('-')?.[0]

  if (supportedLanguages.includes(userLang as SupportedLang)) {
    return userLang as SupportedLang
  }

  return supportedLanguages[0]
}

export const deviceLanguage = getSupportedDeviceLanguage(navigator)

export default deviceParser
