import { useDialog } from 'boards-web-ui'

import { Node } from '../../../models'
import EditImage from '../components/EditImage'
import { IUseSaveNode } from '../models/IUseEditNode'

const useEditImage: IUseSaveNode = (save, isPageEditor) => {
  const { open: openDialog, close: closeDialog } = useDialog()

  return (n: Node) => {
    openDialog(
      <EditImage
        nodeId={n.id}
        initialText={n?.text || ''}
        initialTitle={n?.title || ''}
        onSave={(p) => {
          save(n.id, p)
          if (isPageEditor) {
            closeDialog()
          }
        }}
        isPageEditor={isPageEditor}
      />,
      {
        fullScreen: true,
        blankDialog: true,
      },
    )
  }
}

export default useEditImage
