import React, { useCallback } from 'react'
import { useDialog } from 'boards-web-ui'
import { useDispatch } from 'react-redux'

import useUserFeatures, { Features } from '@hooks/useUserFeatures'
import { ViewSource } from '@features/analytics/models/sourceEvents'
import { Feature } from '@features/profile/hooks/useProfileInformation'

import { commonActions } from '../../../../actions'
import links from '../../../constants/links'
import { deviceLanguage } from '../../../../utils/deviceParser'
import BoardsLimitReached from '../components/BoardsLimitReached'
import BoardsLimitationsAnnouncement from '../components/BoardsLimitationsAnnouncement'

const useBoardsDialogs = () => {
  const dispatch = useDispatch()
  const link = `${links.plansLink}${
    deviceLanguage === 'en' ? '' : `-${deviceLanguage}`
  }`
  const { limit } = useUserFeatures(Features.boards)
  const { open, close } = useDialog()

  const openBoardsLimitationsAnnouncement = useCallback(
    (onUpgrade: () => void) => {
      open(
        <BoardsLimitationsAnnouncement
          limit={limit?.toString()}
          link={link}
          onUpgrade={onUpgrade}
          onCancel={close}
        />,
        {
          className: 'not-default',
          fullScreen: true,
        },
      )
    },
    [open, close, limit, link],
  )

  const openBoardsLimitReached = useCallback(
    (source: ViewSource) => {
      open(
        <BoardsLimitReached
          limit={limit?.toString()}
          onCancel={close}
          source={source}
        />,
        {
          className: 'not-default',
          fullScreen: true,
        },
      )
    },
    [open, close, limit],
  )

  const hideBoardsLimitationsAnnouncement = useCallback(() => {
    dispatch(
      commonActions.setUiState(
        Feature.BOARDS_LIMITATIONS_ANNOUNCEMENT_SHOWN,
        true,
      ),
    )
  }, [dispatch])

  return {
    openBoardsLimitationsAnnouncement,
    openBoardsLimitReached,
    hideBoardsLimitationsAnnouncement,
  } as const
}

export default useBoardsDialogs
