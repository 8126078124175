import React, { useRef } from 'react'

import clsx from 'clsx'
import { useReverseTrialContext } from '@features/reverseTrial/ReverseTrialContext'
import useSideBarBody from '../hooks/useSideBarBody'

import BoardsMenu from '../../boardsMenu/Menu'
import BoardsMenuTitle from '../../boardsMenu/BoardsMenuTitle'

import styles from './SideBarBody.module.css'

type Props = {
  className?: string
  titleClassName?: string
}
const SideBarBody: React.FC<Props> = ({ className, titleClassName }) => {
  const scrollableRef = useRef<HTMLDivElement>(null)
  const { loading, scrollToBottom } = useSideBarBody(scrollableRef)

  const scrollToNewBoard = () => setTimeout(scrollToBottom, 100)
  const { isFreePlanUser } = useReverseTrialContext()

  return (
    <div className={clsx(styles.Root, className)}>
      <BoardsMenuTitle
        className={titleClassName}
        loading={loading}
        onCreate={scrollToNewBoard}
        isFreePlanUser={isFreePlanUser}
      />

      {!loading ? (
        <div className={styles.Scrollable} ref={scrollableRef}>
          <BoardsMenu isFreePlanUser={isFreePlanUser} />
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(SideBarBody)
