import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { User } from '@firebase/auth'
import { commonActions } from 'actions'
import { IListeners } from '../../app/models/listeners'
import { AppDispatch } from '../../app/store'
import { createFirebaseListener } from '../listeners/FirestoreListener'
import ListenersContext from './FirebaseListenersContext'

type Props = PropsWithChildren<{
  user: User | null
  dispatch: AppDispatch
  onLoad?: () => void
}>

const FirebaseListenersProvider: React.FC<Props> = ({
  user,
  dispatch,
  onLoad,
  children,
}) => {
  const {
    start,
    stop,
    startClientConfigListener,
    startBoardFoldersListener,
    startBoardPermissionsListener,
    startBoardUpdatesMessagesListener,
    stopBoardUpdatesMessagesListener,
  } = createFirebaseListener(dispatch)

  useEffect(() => {
    dispatch(commonActions.resetState())
  }, [dispatch])

  useEffect(() => {
    if (user) {
      start(user)

      if (onLoad) onLoad()
    }

    return () => {
      stop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, onLoad])

  const listeners = useMemo<IListeners>(
    () => ({
      startClientConfigListener,
      startBoardFoldersListener,
      startBoardPermissionsListener,
      startBoardUpdatesMessagesListener,
      stopBoardUpdatesMessagesListener,
    }),
    [
      startClientConfigListener,
      startBoardFoldersListener,
      startBoardPermissionsListener,
      startBoardUpdatesMessagesListener,
      stopBoardUpdatesMessagesListener,
    ],
  )

  return (
    <ListenersContext.Provider value={listeners}>
      {children}
    </ListenersContext.Provider>
  )
}

export default FirebaseListenersProvider
