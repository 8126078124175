import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import usePaywall from '@hooks/usePaywall'
import { PaywallSourceEnum } from '@features/analytics/useAccountSubscriptionEvents'
import useSubscriptionPlans from '@features/payments/hooks/useSubscriptionPlans'

const useDialogFromSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { status: plansStatus } = useSubscriptionPlans()
  const { openPaywall } = usePaywall()

  useEffect(() => {
    const dialog = searchParams.get('dialog')
    if (plansStatus === 'loaded' && dialog === 'upgrade') {
      searchParams.delete('dialog')
      setSearchParams(searchParams)

      openPaywall(PaywallSourceEnum.CHROME_EXTENSION_UPGRADE)
    }
    // eslint-disable-next-line
  }, [plansStatus])
}

export default useDialogFromSearchParams
