import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimatedDialog } from 'boards-web-ui'

import { ModalEditWithConfirmationAndTitle } from '../../../../components/ModalEditWithConfirmation'

import PageProfileDialogHeader from './PageProfileDialogHeader'
import PageProfileDialogFooter from './PageProfileDialogFooter'
import PageProfileForm from './PageProfileForm'
import styles from './PageProfileDialog.module.css'

const PageProfileDialog: FC = () => {
  const { t } = useTranslation()

  return (
    <AnimatedDialog
      index={2}
      settings={{
        fullScreen: true,
        blankDialog: true,
        closeOnOverlayClick: false,
        hideParents: true,
        withoutAnimation: true,
        overlayBackgroundColor: 'rgba(0, 0, 0, 0.9)',
      }}
      dialog={
        <ModalEditWithConfirmationAndTitle
          responsiveHeight
          withBorders
          withFixedBody
          className={styles.Root}
          headerControls={
            <PageProfileDialogHeader title={t('label_profile')} />
          }
          footerControls={<PageProfileDialogFooter name={t('action_done')} />}
        >
          <div className={styles.Body}>
            <PageProfileForm />
          </div>
        </ModalEditWithConfirmationAndTitle>
      }
    ></AnimatedDialog>
  )
}

export default PageProfileDialog
