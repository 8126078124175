/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import { random, hash28 } from './Base62'

const idLength = 28
const contentNodeIndicator = '_'

export const boardIndicator = '$'
export const rootFolderIndicatorSource = '!'
export const rootFolderIndicator = '~'

// generates a key that can be used to generate new board/older/content-node id
export const newKey = (length: number = idLength) => random(length)

// generates a new board id based on given key
export const boardId = (key: string) =>
  hash28(`0${key}`).slice(0, -1) + boardIndicator

// generates a new folder id based on given key
export const getFolderId = (index: number | string, key: string) =>
  hash28(index + key)

export const isBoardId = (id: string) => {
  try {
    return id.slice(-1) === boardIndicator
  } catch (error) {
    return false
  }
}
export const isBoardNew = isBoardId

// generates a new content node id based on given key
export const getNodeId = (index: number | string, key: string) =>
  hash28(index + key).slice(0, -1) + contentNodeIndicator

export const isContentNodeId = (id: string) =>
  id.slice(-1) === contentNodeIndicator

export const getRootFolderId = (board?: string): string => {
  try {
    if (board === undefined) {
      console.warn('Router parameter boardId was not found!')
      return ''
    }

    const nodeId = board.split('-').pop() || ''
    return nodeId.replace(boardIndicator, rootFolderIndicator)
  } catch (error) {
    console.error(error)
    return ''
  }
}

export const getBoardIdAndOwner = (board: string) => {
  if (!board) {
    return [null, null]
  }
  return board.split('-')
}

export type CleanOwnerBoard = {
  uid: string | null
  bid: string | null
}
export const getCLeanBoardAndOwnerIds = (board: string): CleanOwnerBoard => {
  const [uid, bid] = getBoardIdAndOwner(board)

  return {
    uid,
    bid: bid?.replace(boardIndicator, '') || null,
  }
}

export const generateNodeId = (): { nodeId: string; key: string } => {
  const key = newKey()
  return { nodeId: getNodeId(0, key), key }
}

export const generateFolderId = (): { nodeId: string; key: string } => {
  const key = newKey()
  return { nodeId: getFolderId(0, key), key }
}
